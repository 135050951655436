import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    fallback: String
  }
  connect() {
    this.handleBeforeStreamRender = this.handleBeforeStreamRender.bind(this);
    document.addEventListener("turbo:before-stream-render", this.handleBeforeStreamRender);
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.handleBeforeStreamRender);
  }

  handleBeforeStreamRender(event) {
    if (/Completed/.test(event.target.innerHTML)) {
      if (/data-new-tab/.test(event.target.innerHTML)) {
        const link = document.createElement('a');
        link.href = window.location.href; // Current location
        link.target = '_blank'; // Opens in new tab
        link.rel = 'noopener noreferrer'; // Security best practices
        link.className = 'btn btn-primary';
        link.style.margin = '10px 0';
        link.textContent = 'Open PDF report in new tab';
        const linkContainer = document.createElement('div');
        this.element.appendChild(linkContainer);
        linkContainer.appendChild(link);

        const messageH4 = document.createElement('h4');
        messageH4.textContent = 'This PDF view is also available in My Reports';
        const messageContainer = document.createElement('div');
        this.element.appendChild(messageContainer);
        messageContainer.appendChild(messageH4);

        const backLink = document.createElement('a');
        backLink.href = this.fallbackValue;
        backLink.textContent = '← Back to generate reports';
        const backLinkContainer = document.createElement('div');
        this.element.appendChild(backLinkContainer);
        backLinkContainer.appendChild(backLink);
      } else if (/data-reload/.test(event.target.innerHTML)) {
        window.location.reload()
      }
    }
  }
}