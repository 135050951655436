(function() {
  document.addEventListener('turbo:load', function() {
    var toptions;
    if ($('body').hasClass('snippets')) {
      tinymce.remove();
      window.suppressPrintCss = true;
      toptions = Object.assign({}, window.tinyMCEFullOptions, {
        toolbar: ["insert | undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak removeformat | table | searchreplace | link unlink | image media | code"],
        image_list: `/clients/${clientSlug}/photos.json`,
        plugins: 'autoresize,advlist,autolink,lists,link,image,charmap,print,preview,searchreplace,visualblocks,code,fullscreen,pagebreak,insertdatetime,media,table,code',
        setup: function(ed) {
          return ed.on('change', function(e) {
            return window.documentApp.handleSavedState(false);
          });
        }
      });
      tinymce.init(toptions);
      return window.setTimeout((function() {
        return $('body').scrollTop(0);
      }), 500);
    }
  });

}).call(this);
