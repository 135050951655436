import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    size: { type: Number, default: 0 },
    fullHeight: { type: Boolean, default: false }
  }

  connect() {
    if (window.screen.width >= this.sizeValue) {
      this.element.classList.remove('d-none')
    }

    if (this.fullHeightValue) {
      this.setHeightToWindow()
    }
  }

  setHeightToWindow() {
    const minHeight = $(window).height() - $('.navbar-top').height() - $('.navbar-secondary').height()
    return $(this.element).css('min-height', minHeight);
  };

}
