import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  blur() {
    this.submitButtonTarget.blur()
  }

  turboSubmit(_) {
    window.pleaseWait()
    Rails.fire(this.element, 'submit')
  }

  submitEnd(_) {
    window.unblockUI()
  }
}
