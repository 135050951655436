var saveBooking;

import Rails from '@rails/ujs';

import Bloodhound from 'bloodhound-js';

import moment from 'moment';

import ClipboardJS from 'clipboard';

import flatpickr from 'flatpickr';

import autosize from '../../src/autosize';

window.bookingApp = {
  saved: true,
  step: 'general',
  bookingId: null,
  startDate: null,
  endDate: null,
  startDatePicker: null,
  endDatePicker: null,
  meetingDates: [],
  autoWash: null,
  functionTypeList: [],
  propertyIds: [],
  propertySelected: null,
  clientId: null,
  menuIds: [],
  dateRange: [],
  initializeTinyMCE: function() {
    var toptions;
    tinymce.remove();
    toptions = Object.assign({}, window.tinyMCEOptions, {
      setup: function(ed) {
        return ed.on('change', function(e) {
          return window.bookingApp.handleSavedState(false);
        });
      }
    });
    tinymce.init(toptions);
    return window.setTimeout((function() {
      return $('body').scrollTop(0);
    }), 500);
  },
  getDates: function(startDate, stopDate) {
    var currentDate, dateArray;
    dateArray = new Array();
    currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate));
      currentDate = currentDate.add(1, 'days');
    }
    return dateArray;
  },
  getDateRange: function(startDate, endDate) {
    var d, dates, i, len, range;
    dates = [];
    range = this.getDates(moment(startDate), moment(endDate));
    for (i = 0, len = range.length; i < len; i++) {
      d = range[i];
      dates.push({
        date: d,
        long: d.format('dddd, MMMM Do'),
        human: d.format('M/D'),
        humanWithDay: d.format('ddd<br/>M/D'),
        short: d.format('YYYY-MM-DD')
      });
    }
    return dates;
  },
  keyifyDates: function(start, end) {
    return start + '|' + end;
  },
  blockUI: function() {
    return $.blockUI({
      message: null,
      fadeIn: 100,
      fadeOut: 100,
      overlayCSS: {
        backgroundColor: '#000',
        opacity: .2,
        cursor: 'wait'
      },
      css: {
        border: 'none',
        padding: '10px',
        fontSize: '12px',
        backgroundColor: '#000',
        '-webkit-border-radius': '10px',
        '-moz-border-radius': '10px',
        opacity: .2,
        color: '#fff'
      }
    });
  },
  saveBooking: function(target) {
    if (this.shouldConfirmPropertyChange()) {
      if (window.confirm("Changing the property will remove the existing room block.")) {
        return this.performSave(target);
      }
    } else {
      return this.performSave(target);
    }
  },
  shouldConfirmPropertyChange: function() {
    return window.bookingApp.propertySelected && window.bookingApp.propertyIds.length !== 0 && !window.bookingApp.propertyIds.includes(parseInt(window.bookingApp.propertySelected));
  },
  performSave: function(target) {
    window.bookingApp.blockUI();
    $('form.booking-form').one('ajax:complete', function() {
      window.bookingApp.handleSavedState(true);
      if (target.match(/\/functions/)) {
        // do not use Turbo Drive for function diary; breaks the back button
        return window.top.location.href = target;
      } else {
        return Turbo.visit(target);
      }
    });
    tinymce.triggerSave();
    Rails.fire(document.querySelector('form.booking-form'), 'submit');
    return window.bookingApp.propertyIds = [];
  },
  handleDatePicker: function() {
    var dateOptions;
    this.datePickerEventHandler($('#booking_contract_due'));
    this.datePickerEventHandler($('#booking_cut_off_date'));
    this.datePickerEventHandler($('#booking_rooming_list'));
    this.datePickerEventHandler($('#booking_cancel_by'));
    this.datePickerEventHandler($('#booking_guarantee_by'));
    this.datePickerEventHandler($('#booking_beo_due'));
    this.datePickerEventHandler($('#booking_menu_selection_due'));
    this.datePickerEventHandler($('#booking_deposit_due'));
    this.datePickerEventHandler($('#booking_deposit_2_due'));
    this.datePickerEventHandler($('#booking_deposit_3_due'));
    this.datePickerEventHandler($('#booking_final_payment_due'));
    this.datePickerEventHandler($('#booking_booking_payments_attributes_0_payment_date'));
    this.datePickerEventHandler($('.pending_auto_trace'));
    this.datePickerEventHandler($('.booking-golf-date'), 'l, M j, Y');
    this.datePickerEventHandler($('#booking_turned_prospect_at'));
    this.datePickerEventHandler($('#booking_turned_tentative_at'));
    this.datePickerEventHandler($('#booking_turned_definite_at'));
    this.datePickerEventHandler($('#booking_turned_lost_at'));
    this.datePickerEventHandler($('#booking_turned_cancelled_at'));
    this.startDatePicker = flatpickr('#booking_start_date', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange: this.startDateChanged
    });
    this.endDatePicker = flatpickr('#booking_end_date', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      minDate: $('#booking_start_date').val(),
      onChange: this.endDateChanged
    });
    return dateOptions = {
      buttonImageOnly: true,
      buttonText: "",
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd',
      showOn: 'both'
    };
  },
  startDateChanged: function(selectedDates, selectedDate) {
    var currentEndDate, currentStartDate, dateString, i, len, meetingsLost, newStartDate;
    $('.meetings-lost').empty();
    $('.meetings-will-be-lost').hide();
    if (window.bookingApp.startDate === "") {
      window.bookingApp.startDate = selectedDate;
      return window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`);
    } else {
      currentStartDate = moment(window.bookingApp.startDate);
      currentEndDate = moment(window.bookingApp.endDate);
      newStartDate = moment(selectedDate);
      if (newStartDate.isAfter(currentEndDate)) {
        // moving
        window.bookingApp.startDate = selectedDate;
        return window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`);
      } else if (newStartDate.isAfter(currentStartDate)) {
        // moving or making it shorter on the front end
        meetingsLost = window.bookingApp.meetingDates.filter(function(dateString) {
          return moment(dateString).isBefore(newStartDate);
        });
        if (meetingsLost.length > 0) {
          $('.meetings-will-be-lost').show();
          for (i = 0, len = meetingsLost.length; i < len; i++) {
            dateString = meetingsLost[i];
            $('.meetings-lost').append(`${moment(dateString).format('MMMM D')}<br>`);
          }
        }
        return $('.start-date-change-modal').modal('show');
      } else {
        // moving or making it longer on the front end
        return $('.start-date-change-modal').modal('show');
      }
    }
  },
  endDateChanged: function(selectedDates, selectedDate) {
    var currentEndDate, dateString, i, len, meetingsLost, newEndDate;
    // if there are meetings *after* the *new* end date, meetings will be affected
    if (window.bookingApp.endDate === "") {
      window.bookingApp.endDate = selectedDate;
      return window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`);
    } else {
      newEndDate = moment(selectedDate);
      currentEndDate = moment(window.bookingApp.endDate);
      meetingsLost = window.bookingApp.meetingDates.filter(function(dateString) {
        return moment(dateString).isAfter(newEndDate);
      });
      if (meetingsLost.length > 0) {
        $('.meetings-lost').empty();
        for (i = 0, len = meetingsLost.length; i < len; i++) {
          dateString = meetingsLost[i];
          $('.meetings-lost').append(`${moment(dateString).format('MMMM D')}<br>`);
        }
        return $('.end-date-change-modal').modal('show');
      } else {
        window.bookingApp.endDate = selectedDate;
        return window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`);
      }
    }
  },
  datePickerEventHandler: function(elem, format) {
    var flatpickrOptions;
    flatpickrOptions = {
      altInput: true,
      altFormat: format || 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange: function() {
        return window.bookingApp.handleSavedState(false);
      }
    };
    return flatpickr(elem, flatpickrOptions);
  },
  handleAccountSelect: function() {
    return $('#booking_account_id').select2({
      ajax: {
        url: '/accounts-search.json',
        dataType: 'json',
        delay: 250,
        cache: true,
        data: function(params) {
          return {
            query: params.term
          };
        },
        processResults: function(data) {
          return {
            results: data
          };
        }
      },
      minimumInputLength: 2,
      escapeMarkup: function(markup) {
        return markup;
      },
      templateResult: function(account) {
        if (account.name) {
          return `<p><strong>${account.name}</strong><br/>${account.city_state}</p>`;
        }
      }
    });
  },
  handleSavedState: function(saved) {
    var $saveBookingButton;
    window.bookingApp.saved = saved;
    $saveBookingButton = $('.save-booking');
    if (window.bookingApp.saved) {
      $saveBookingButton.addClass('disabled');
      return $saveBookingButton.html('Saved');
    } else {
      $saveBookingButton.removeClass('disabled');
      return $saveBookingButton.html('Save Changes');
    }
  },
  generalStep: {
    configureTimepicker: function() {
      var timepickerOptions;
      timepickerOptions = {
        timeFormat: timepickerFormat
      };
      $('#booking_eta').timepicker(timepickerOptions);
      return $('#booking_etd').timepicker(timepickerOptions);
    }
  },
  roomsStep: {
    manageDailyRateVisibility: function() {
      if ($('input#booking_daily_rate').val() === 'true') {
        $('.daily-rate').show();
        return $('.no-daily-rate').hide();
      } else {
        $('.daily-rate').hide();
        return $('.no-daily-rate').show();
      }
    },
    manageDepartureDateInputsVisibility: function() {
      var msg;
      msg = $('#has-booking-rooms-on-departure-date-message');
      if ($('#booking-has-booking-rooms-on-departure-date').prop('checked')) {
        $('.departure_date_input').prop('disabled', false);
        if ((msg != null) && msg.hasClass('hidden')) {
          return msg.removeClass('hidden');
        }
      } else {
        $('.departure_date_input').prop('disabled', true);
        if ((msg != null) && !msg.hasClass('hidden')) {
          return msg.addClass('hidden');
        }
      }
    },
    updateTotals: function(inputFields, date) {
      var adr, count, i, input, len, name, numberTotal, rate, rateTotal, results, roomId;
      rateTotal = 0;
      numberTotal = 0;
      results = [];
      for (i = 0, len = inputFields.length; i < len; i++) {
        input = inputFields[i];
        name = input.placeholder === "Qty" ? 'gross' : 'net';
        if (input.value.length > 0 && $.isNumeric(input.value)) {
          roomId = parseInt(input.dataset.roomTypeId);
          rate = parseFloat($(".booking-rate-for-room-type-" + roomId + "-and-date-" + date).val());
          count = parseInt(input.value);
          rateTotal += rate * count;
          numberTotal += count;
        }
        adr = ((rateTotal / numberTotal) || 0).toFixed(2);
        results.push($("table tfoot tr th[data-date='" + date + "'][data-name='" + name + "']").html(`${numberTotal} / ${adr}`));
      }
      return results;
    }
  },
  pickupStep: {
    managePickupDailyRateVisibility: function() {
      if ($('input#booking_pickup_daily_rate').val() === 'true') {
        $('.pickup-daily-rate').show();
        return $('.no-pickup-daily-rate').hide();
      } else {
        $('.pickup-daily-rate').hide();
        return $('.no-pickup-daily-rate').show();
      }
    }
  },
  meetingSpaceStep: {
    changed: function() {
      window.bookingApp.meetingSpaceStep.initializeTooltips();
      window.bookingApp.meetingSpaceStep.managePosition();
      window.bookingApp.meetingSpaceStep.configureTimepicker();
      window.bookingApp.meetingSpaceStep.fillCopyFromDropdowns();
      window.bookingApp.meetingSpaceStep.configureFunctionTypeTypeahead();
      return window.bookingApp.handleSavedState(false);
    },
    managePosition: function() {
      var i, index, len, ref, results, table, tr;
      ref = $('table.booking-meeting-space-edit');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        table = ref[i];
        results.push((function() {
          var j, len1, ref1, results1;
          ref1 = $(table).find('tr.nested-fields:visible');
          results1 = [];
          for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
            tr = ref1[index];
            results1.push($(tr).find('.booking-meeting-position').val(index + 1));
          }
          return results1;
        })());
      }
      return results;
    },
    configureTimepicker: function() {
      var timepickerOptions;
      timepickerOptions = {
        timeFormat: timepickerFormat
      };
      $('.booking-meeting-start-time').timepicker(timepickerOptions);
      return $('.booking-meeting-end-time').timepicker(timepickerOptions);
    },
    initializeTooltips: function() {
      return $('tr.nested-fields a').tooltip();
    },
    extractDataFromRow: function(row) {
      return {
        date: row.find("input.booking-meeting-date").val(),
        start_time: row.find("input.booking-meeting-start-time").val(),
        end_time: row.find("input.booking-meeting-end-time").val(),
        setup_time: row.find("input.booking-meeting-setup-time").val(),
        breakdown_time: row.find("input.booking-meeting-breakdown-time").val(),
        function: row.find("input.booking-meeting-function").val(),
        number: row.find("input.booking-meeting-number").val(),
        guarantee: row.find("input.booking-meeting-guarantee").val(),
        forecast_rental_revenue: row.find("input.booking-meeting-rental").val(),
        fbmin: row.find("input.booking-meeting-fbmin").val(),
        meeting_room_id: row.find("select.booking-meeting-meeting-room-id").val(),
        setup_id: row.find("select.booking-meeting-setup-id").val()
      };
    },
    duplicateRow: function(row, newDate) {
      var $markup, domPrefix, newId, rowData, template;
      rowData = window.bookingApp.meetingSpaceStep.extractDataFromRow(row);
      template = $(`.add-booking-meeting-fields-for-${rowData.date}`).data('association-insertion-template');
      newId = new Date().getTime() + Math.floor(Math.random() * 1000);
      $markup = $(template.replace(/new_booking_meetings/g, newId));
      domPrefix = `#booking_booking_meetings_attributes_${newId}`;
      $(`${domPrefix}_date`, $markup).val(newDate || rowData.date);
      $(`${domPrefix}_start_time`, $markup).val(rowData.start_time);
      $(`${domPrefix}_end_time`, $markup).val(rowData.end_time);
      $(`${domPrefix}_function`, $markup).val(rowData.function);
      $(`${domPrefix}_number`, $markup).val(rowData.number);
      $(`${domPrefix}_guarantee`, $markup).val(rowData.guarantee);
      $(`${domPrefix}_setup_time`, $markup).val(rowData.setup_time);
      $(`${domPrefix}_breakdown_time`, $markup).val(rowData.breakdown_time);
      $(`${domPrefix}_forecast_rental_revenue`, $markup).val(rowData.forecast_rental_revenue);
      $(`${domPrefix}_fbmin`, $markup).val(rowData.fbmin);
      $(`${domPrefix}_meeting_room_id`, $markup).val(rowData.meeting_room_id);
      $(`${domPrefix}_setup_id`, $markup).val(rowData.setup_id);
      return $markup;
    },
    datesWithMeetings: function() {
      var dates, i, len, ref, table;
      dates = [];
      ref = $('table.booking-meeting-space-edit');
      for (i = 0, len = ref.length; i < len; i++) {
        table = ref[i];
        if ($(table).find('tr.nested-fields:visible').length > 0) {
          dates = dates.concat($(table).data('date'));
        }
      }
      return dates;
    },
    otherDatesWithMeetings: function(date) {
      var dates, index;
      dates = this.datesWithMeetings();
      index = dates.indexOf(date);
      if (index > -1) {
        dates.splice(index, 1);
      }
      return dates;
    },
    fillCopyFromDropdowns: function() {
      var $ul, button, date, dates, i, len, ref, results, thisDate, ul;
      ref = $('.copy-from.dropdown-menu');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        ul = ref[i];
        $ul = $(ul);
        thisDate = $ul.data('date');
        $ul.empty();
        dates = this.otherDatesWithMeetings(thisDate);
        button = $ul.parent('.btn-group');
        if (dates.length > 0) {
          button.show();
          results.push((function() {
            var j, len1, results1;
            results1 = [];
            for (j = 0, len1 = dates.length; j < len1; j++) {
              date = dates[j];
              results1.push($ul.append(`<li><a href=\"#\" class=\"booking-meeting-do-copy\" data-from=\"${date}\" data-to=\"${thisDate}\">${moment(date).format('dddd, MMMM D')}</a></li>`));
            }
            return results1;
          })());
        } else {
          results.push(button.hide());
        }
      }
      return results;
    },
    sorting: function() {
      return $('tbody.bm-sortable').sortable({
        helper: window.sortableFixHelper,
        scroll: false,
        connectWith: '.connected',
        items: '>*:not(.nosort)',
        stop: function(event, ui) {
          return window.bookingApp.meetingSpaceStep.sorted(ui.item);
        }
      });
    },
    sorted: function(item) {
      var parentTable;
      parentTable = item.parents('table');
      item.find('.booking-meeting-date').val(parentTable.data('date'));
      return this.changed();
    },
    configureFunctionTypeTypeahead: function() {
      var functionTypes;
      functionTypes = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: window.bookingApp.functionTypeList
      });
      return $('input.booking-meeting-function:not(.tt-input,.tt-hint)').typeahead({
        hint: false
      }, {
        name: 'functionType',
        source: functionTypes
      });
    }
  },
  documentsStep: {
    handleCopyToClipboard: function() {
      var clipboard;
      clipboard = new ClipboardJS('.copy-to-clipboard');
      return clipboard.on('success', function(event) {
        var $clipButton, cb;
        $clipButton = $(event.trigger);
        $clipButton.tooltip({
          title: 'Copied!'
        });
        $clipButton.tooltip('show');
        cb = function(elem) {
          return elem.tooltip('destroy');
        };
        return setTimeout((function() {
          return cb($clipButton);
        }), 1500);
      });
    }
  },
  handleBookingEdit: function() {
    var self;
    $('input[data-toggle="toggle"]').bootstrapToggle();
    self = this;
    self.handleDatePicker();
    self.handleAccountSelect();
    switch (self.step) {
      case 'general':
        self.generalStep.configureTimepicker();
        $('#booking_revenue_classification_id').select2(window.select2OptionsWithoutClear);
        $('#booking_business_type_id').select2(window.select2OptionsWithoutClear);
        $('#booking_business_origin_id').select2(window.select2OptionsWithoutClear);
        return $('#booking_meeting_class_id').select2(window.select2OptionsWithoutClear);
      case 'people':
        return $('#booking_lead_source_id').select2(window.select2OptionsWithoutClear);
      case 'rooms':
        self.roomsStep.manageDailyRateVisibility();
        return self.roomsStep.manageDepartureDateInputsVisibility();
      case 'pickup':
        return self.pickupStep.managePickupDailyRateVisibility();
      case 'meeting_space':
        self.meetingSpaceStep.initializeTooltips();
        self.meetingSpaceStep.configureTimepicker();
        self.meetingSpaceStep.fillCopyFromDropdowns();
        self.meetingSpaceStep.sorting();
        return self.meetingSpaceStep.configureFunctionTypeTypeahead();
      case 'documents':
        return self.documentsStep.handleCopyToClipboard();
      case 'department_notes':
        return autosize($('.department-notes textarea'));
    }
  }
};

// handle saved state
$(document).on('input change propertychange paste', 'body.bookings.edit input, body.bookings.edit textarea', function() {
  return window.bookingApp.handleSavedState(false);
});

$(document).on('change', 'body.bookings.edit select', function() {
  return window.bookingApp.handleSavedState(false);
});

$(document).on('page:change', function() {
  return $('#booking-meetings').on('cocoon:after-insert cocoon:after-remove', function() {
    return window.bookingApp.meetingSpaceStep.changed();
  });
});

// handle save changes button
$(document).on('click', '.save-booking', function(event) {
  event.preventDefault();
  return window.bookingApp.saveBooking(window.location.pathname);
});

// handle complete booking button
$(document).on('click', '.complete-booking', function(event) {
  event.preventDefault();
  return window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/complete`);
});

// handle copy booking
$(document).on('click', 'a.copy-booking', function(event) {
  event.preventDefault();
  $('.copy-booking-modal div.modal-body iframe').attr('src', this.href);
  return $('.copy-booking-modal').modal('show');
});

// print booking modal
$(document).on('click', 'a.print_booking', function(event) {
  event.preventDefault();
  return $('.print_booking_recap').modal('show');
});

// handle step navigation
$(document).on('click', '.booking-steps li a', function(event) {
  var $this, step, target;
  event.preventDefault();
  $this = $(this);
  step = $this.data('step');
  window.bookingApp.step = step;
  target = $this.attr('href');
  return window.bookingApp.saveBooking(target);
});

// handle group type change
saveBooking = function() {
  var target;
  target = `/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`;
  return window.bookingApp.saveBooking(target);
};

$(document).on('change', 'select#booking_group_type', saveBooking);

// handle account change
$(document).on('change', 'select#booking_account_id', saveBooking);

// handle contact change
$(document).on('change', 'select#booking_contact_id', saveBooking);

// handle booking-agent change
// handle booking-agent-contact change
$(document).on('change', 'select.booking_booking_agent_contact', saveBooking);

// handle property change
$(document).on('change', 'select#booking_property_id', function(event) {
  return window.bookingApp.propertySelected = event.currentTarget.options[event.currentTarget.options.selectedIndex].value;
});

// start date change, cancel button
$(document).on('click', '.start-date-change-cancel', function() {
  var originalStartDate;
  originalStartDate = window.bookingApp.startDate;
  $('#booking_start_date').val(originalStartDate);
  return $('.start-date-change-modal').modal('hide');
});

// end date change, continue button
$(document).on('click', '.end-date-change-continue', saveBooking);

// end date change, cancel button
$(document).on('click', '.end-date-change-cancel', function() {
  var originalEndDate;
  originalEndDate = window.bookingApp.endDate;
  $('#booking_end_date').val(originalEndDate);
  return $('.end-date-change-modal').modal('hide');
});

// general: create Cloudbeds group profile
$(document).on('click', '.cloudbeds-create-group-profile', function() {
  var target;
  event.preventDefault();
  target = $(this).attr('href');
  return window.bookingApp.saveBooking(target);
});

// rooms: disable/enable departure date input switch flipped
$(document).on('change', '#booking-has-booking-rooms-on-departure-date', function(event, state) {
  return window.bookingApp.roomsStep.manageDepartureDateInputsVisibility();
});

// rooms & pickup: pickup complete switch flipped
$(document).on('change', '#booking-pickup-complete', function(event, state) {
  var $pickupComplete, $this, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $pickupComplete = $('input#booking_pickup_complete');
  $pickupComplete.val(checked);
  return window.bookingApp.roomsStep.manageDailyRateVisibility();
});

// rooms: daily rate switch flipped
$(document).on('change', '#booking-use-daily-rate', function(event, state) {
  var $this, $useDailyRate, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $useDailyRate = $('input#booking_daily_rate');
  $useDailyRate.val(checked);
  return window.bookingApp.roomsStep.manageDailyRateVisibility();
});

// rooms: one rate for all dates
$(document).on('input propertychange keyup', '.booking-one-rate', function(event) {
  var $this;
  $this = $(this);
  return $('input.booking-rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.booking-rooms-quantity', function(event) {
  var $this, date, rate, roomTypeId;
  if ($('#booking_daily_rate').val() !== 'true') {
    $this = $(this);
    roomTypeId = $this.data('room-type-id');
    date = $this.data('date');
    rate = $(`#rate_${roomTypeId}`).val();
    return $(`.booking-rate-for-room-type-${roomTypeId}-and-date-${date}`).val(rate);
  }
});

// rooms: auto-wash
$(document).on('blur', 'input.booking-rooms-quantity', function(event) {
  var $expectField, $this, date, index, inputFields;
  $this = $(this);
  index = $this.attr('id').replace('booking_booking_rooms_attributes_', '').replace('_quantity', '');
  $expectField = $(`#booking_booking_rooms_attributes_${index}_expect`);
  if (!($expectField.val() || !$this.val() || !$.isNumeric($this.val()))) {
    $expectField.val(Math.floor($this.val() * window.bookingApp.autoWash));
  }
  // rooms: quantity change
  if ($.isNumeric($this.val()) && this.defaultValue !== $this.val()) {
    date = $this.data().date;
    if ($this.attr('id').includes('quantity')) {
      inputFields = $("input[placeholder='Qty'][data-date='" + date + "']");
    } else {
      inputFields = $("input[placeholder='Expect'][data-date='" + date + "']");
    }
    return window.bookingApp.roomsStep.updateTotals(inputFields, date);
  }
});

// rooms: rate change - recalculate ADR
$(document).on('blur', 'input.booking-one-rate, .booking-daily-rate', function(event) {
  var $this, date, gross, i, len, net, ref, results;
  $this = $(this);
  if ($.isNumeric(parseFloat($this.val())) && this.defaultValue !== $this.val()) {
    ref = window.bookingApp.dateRange;
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      date = ref[i];
      gross = $("input[placeholder='Qty'][data-date='" + date + "']");
      net = $("input[placeholder='Expect'][data-date='" + date + "']");
      window.bookingApp.roomsStep.updateTotals(gross, date);
      results.push(window.bookingApp.roomsStep.updateTotals(net, date));
    }
    return results;
  }
});

// rooms: category navigation
$(document).on('click', 'ul.room-types-nav li a', function(event) {
  var $this, category;
  $this = $(this);
  category = $this.attr('rel');
  window.roomTypeCategoryId = category;
  event.preventDefault();
  $('ul.room-types-nav li a').removeClass('current');
  $this.addClass('current');
  if (category === 'All') {
    $('tr.category').show();
  } else {
    $('tr.category').hide();
    $('tr.category-' + category).show();
  }
  return window.bookingApp.roomsStep.manageDailyRateVisibility();
});

// pickup: daily rate switch flipped
$(document).on('change', '#booking-use-pickup-daily-rate', function(event, state) {
  var $this, $usePickupDailyRate, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $usePickupDailyRate = $('input#booking_pickup_daily_rate');
  $usePickupDailyRate.val(checked);
  $('.no-daily-rate').toggle();
  return window.bookingApp.pickupStep.managePickupDailyRateVisibility();
});

// pickup: one rate for all dates
$(document).on('input propertychange keyup', '.booking-pickup-one-rate', function(event) {
  var $this;
  $this = $(this);
  return $('input.booking-pickup-rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.booking-pickup-quantity', function(event) {
  var $this, date, rate, roomTypeId;
  if ($('#booking-use-pickup-daily-rate').val() !== 'true') {
    $this = $(this);
    roomTypeId = $this.data('room-type-id');
    date = $this.data('date');
    rate = $(`#rate_${roomTypeId}`).val();
    return $(`.booking-pickup-rate-for-room-type-${roomTypeId}-and-date-${date}`).val(rate);
  }
});

// meeting space: add a meeting
$(document).on('click', 'a.add-booking-meeting', function(event) {
  // mostly handled by cocoon
  return window.bookingApp.meetingSpaceStep.changed();
});

// meeting space: copy meeting only - shows warning
$(document).on('click', '.copy-options-meeting-only', function(event) {
  var $fromTable, $markup, $toTable, fromDate, i, j, len, len1, ref, ref1, removeLink, toDate, tr;
  event.preventDefault();
  fromDate = $('.copy-options-modal').data('data-from');
  toDate = $('.copy-options-modal').data('data-to');
  $toTable = $(`.meetings-for-${toDate} tbody`);
  $fromTable = $(`.meetings-for-${fromDate} tbody`);
  ref = $toTable.find('a.remove_fields');
  for (i = 0, len = ref.length; i < len; i++) {
    removeLink = ref[i];
    $(removeLink).trigger('click');
  }
  ref1 = $fromTable.find('tr.nested-fields:visible');
  for (j = 0, len1 = ref1.length; j < len1; j++) {
    tr = ref1[j];
    $markup = window.bookingApp.meetingSpaceStep.duplicateRow($(tr), toDate);
    $toTable.append($markup);
  }
  window.bookingApp.meetingSpaceStep.changed();
  return $('.copy-options-modal').modal('hide');
});

// meeting space: copy meeting only
$(document).on('click', '.meeting-copy-continue', function(event) {
  var $fromTable, $markup, $toTable, fromDate, i, j, len, len1, ref, ref1, removeLink, toDate, tr;
  fromDate = $('.copy-options-modal').data('data-from');
  toDate = $('.copy-options-modal').data('data-to');
  $toTable = $(`.meetings-for-${toDate} tbody`);
  $fromTable = $(`.meetings-for-${fromDate} tbody`);
  ref = $toTable.find('a.remove_fields');
  for (i = 0, len = ref.length; i < len; i++) {
    removeLink = ref[i];
    $(removeLink).trigger('click');
  }
  ref1 = $fromTable.find('tr.nested-fields:visible');
  for (j = 0, len1 = ref1.length; j < len1; j++) {
    tr = ref1[j];
    $markup = window.bookingApp.meetingSpaceStep.duplicateRow($(tr), toDate);
    $toTable.append($markup);
  }
  window.bookingApp.meetingSpaceStep.changed();
  return $('.copy-meeting-warning-modal').modal('hide');
});

// meeting space: row duplication
$(document).on('click', 'a.booking-meeting-duplicate-row', function(event) {
  var $markup, $original;
  event.preventDefault();
  $original = $(this).closest('tr');
  $markup = window.bookingApp.meetingSpaceStep.duplicateRow($original);
  $original.after($markup);
  return window.bookingApp.meetingSpaceStep.changed();
});

//show modal with options for copying
$(document).on('click', 'a.booking-meeting-do-copy', function(event) {
  event.preventDefault();
  $('.copy-options-modal').modal('show');
  $('.copy-options-modal').data('data-from', this.dataset.from);
  $('.copy-options-modal').data('data-to', this.dataset.to);
  // $('.hidden-copy-from').val(this.dataset.from)
  // $('.hidden-copy-to').val(this.dataset.to)
  window.bookingApp.everythingCopyFrom = this.dataset.from;
  return window.bookingApp.everythingCopyTo = this.dataset.to;
});

//meeting space: copy everything - shows warning
$(document).on('click', '.copy-options-everything', function(event) {
  event.preventDefault();
  if (window.bookingApp.everythingCopyFrom && window.bookingApp.everythingCopyTo) {
    $.post('/copy_day_meetings', {
      copy_from: window.bookingApp.everythingCopyFrom,
      copy_to: window.bookingApp.everythingCopyTo,
      booking_id: window.bookingApp.bookingId
    }).done(function(response) {
      return window.location.reload();
    });
    return $('.copy-everything-warning-modal').modal('hide');
  }
});

// meeting space: data changes
$(document).on('click', 'table.booking-meeting-space-edit .delete-booking-meeting', (event) => {
  return window.bookingApp.meetingSpaceStep.changed();
});

$(document).on('blur', 'table.booking-meeting-space-edit input', (event) => {
  return window.bookingApp.meetingSpaceStep.changed();
});

$(document).on('change', 'table.booking-meeting-space-edit select', (event) => {
  return window.bookingApp.meetingSpaceStep.changed();
});

$(document).on('keydown', 'table.booking-meeting-space-edit input', (event) => {
  var code;
  code = event.keyCode ? event.keyCode : event.which;
  if (code === 13) {
    return window.bookingApp.meetingSpaceStep.changed();
  }
});

// meeting space: view in function diary
$(document).on('click', '.view-in-fd', function(event) {
  var target;
  event.preventDefault();
  target = $(this).attr('href');
  return window.bookingApp.saveBooking(target);
});

// meeting space: goto meeting detail
$(document).on('click', '.goto-booking-meeting-detail', function(event) {
  var target;
  event.preventDefault();
  target = $(this).attr('href');
  return window.bookingApp.saveBooking(target);
});

// meeting space: quick forecasting
$(document).on('click', '.booking-meeting-forecast', function(event) {
  var target;
  event.preventDefault();
  target = $(this).attr('href');
  $('.booking-meeting-forecast-modal .modal-body').load(target);
  return $('.booking-meeting-forecast-modal').modal('show');
});

// payments: delete payment
$(document).on('click', '.delete-payment', function(event) {
  return window.bookingApp.blockUI();
});

// activity: quick actions
$(document).on('click', 'body.bookings .quick-action', function(event) {
  var $textarea, cursorPos, textAfter, textBefore, v;
  event.preventDefault();
  $textarea = $('#activity_notes');
  cursorPos = $textarea.prop('selectionStart');
  v = $textarea.val();
  textBefore = v.substring(0, cursorPos);
  textAfter = v.substring(cursorPos, v.length);
  return $textarea.val(textBefore + $(this).html() + textAfter);
});

// activity: filter by activity type
$(document).on('change', 'body.bookings #activity_type_filter', function(event) {
  var filter;
  filter = $(this).val();
  return Turbo.visit(`/bookings/${window.bookingApp.bookingId}/edit/activity?filter=${filter}`);
});

// documents: send for e-signature
$(document).on('click', 'body.bookings .send-selected-for-esignature', function(event) {
  var bdocs, create_href, elem;
  event.preventDefault();
  bdocs = (function() {
    var i, len, ref, results;
    ref = $('.booking-document-for-esignature:checked');
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      elem = ref[i];
      results.push($(elem).data('booking-document'));
    }
    return results;
  })();
  if (bdocs.length > 0 || $('#select_cc_auth_form').prop('checked')) {
    window.bookingApp.blockUI();
    create_href = `/bookings/${window.bookingApp.bookingId}/signatures`;
    return $.post(create_href, {
      booking_document_ids: bdocs,
      format: 'json'
    }, function(data) {
      var target;
      target = `/signatures/${data.id}/edit`;
      if ($('#select_cc_auth_form').prop('checked')) {
        target += "?add_cc_auth_form=true";
      }
      return Turbo.visit(target);
    });
  }
});

// golf: remove golf info
$(document).on('click', '.remove-golf-info', function(event) {
  var $this;
  event.preventDefault();
  $this = $(this);
  $this.parent().find('input.destroy').val('1');
  return $this.closest('tr').hide();
});

// golf: calculate total cost
$(document).on('change', 'body.bookings .cost-per-player, .players', function(event) {
  var $this, $tr, costPerPlayer, players, totalCost;
  $this = $(this);
  $tr = $this.closest('tr');
  players = $tr.find('.players').val();
  costPerPlayer = $tr.find('.cost-per-player').val();
  if ($.isNumeric(players) && $.isNumeric(costPerPlayer)) {
    totalCost = (players * costPerPlayer).toFixed(2);
    return $tr.find('.total-cost').val(totalCost);
  }
});

// golf: calculate total cost (actual)
$(document).on('change', 'body.bookings .actual-cost-per-player, .actual-players', function(event) {
  var $this, $tr, costPerPlayer, players, totalCost;
  $this = $(this);
  $tr = $this.closest('tr');
  players = $tr.find('.actual-players').val();
  costPerPlayer = $tr.find('.actual-cost-per-player').val();
  if ($.isNumeric(players) && $.isNumeric(costPerPlayer)) {
    totalCost = (players * costPerPlayer).toFixed(2);
    return $tr.find('.actual-total-cost').val(totalCost);
  }
});

// packages
$(document).on('change', 'body.bookings select#booking_booking_packages_attributes_0_package_id', function(event) {
  var bookingId, id;
  event.preventDefault();
  id = $(this).val();
  bookingId = window.bookingApp.bookingId;
  return $.get(`/bookings/${bookingId}/booking_packages/pkg_info`, {
    id: id
  }, function(data) {
    $('#booking_booking_packages_attributes_0_cost').val(data.cost);
    $('#booking_booking_packages_attributes_0_unit').val(data.unit);
    return $('#select2-booking_booking_packages_attributes_0_unit-container').text(data.unit);
  });
});

document.addEventListener('turbo:load', function() {
  var $body;
  $body = $('body');
  if ($body.hasClass('bookings') && $body.hasClass('edit')) {
    window.bookingApp.handleBookingEdit();
    window.bookingApp.initializeTinyMCE();
    $('#banquet-check-by-date-link').on('click', function(e) {
      e.preventDefault();
      $('#banquet-check-by-date').removeClass('hidden');
      $('#banquet-check-by-function').addClass('hidden');
      return $('#banquet-check-by-category').addClass('hidden');
    });
    $('#banquet-check-by-function-link').on('click', function(e) {
      e.preventDefault();
      $('#banquet-check-by-date').addClass('hidden');
      $('#banquet-check-by-function').removeClass('hidden');
      return $('#banquet-check-by-category').addClass('hidden');
    });
    return $('#banquet-check-by-category-link').on('click', function(e) {
      e.preventDefault();
      $('#banquet-check-by-date').addClass('hidden');
      $('#banquet-check-by-function').addClass('hidden');
      return $('#banquet-check-by-category').removeClass('hidden');
    });
  }
});
