import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["imageDisplay"]
  static values = {
    imageUrl: String
  }

  showImage() {
    const display = this.imageDisplayTarget
    if (!display.firstChild) {
      const img = document.createElement('img')
      img.onerror = () => {
        console.error("Failed to load image from:", this.imageUrlValue)
        display.textContent = "Image not available"
      }
      img.onload = () => {
        console.log("Successfully loaded image")
      }
      let url = this.imageUrlValue
      console.log("Attempting to load image from:", url)
      img.src = url
      img.style.maxWidth = '125px'
      display.appendChild(img)
    }
    display.classList.remove('d-none')
  }

  hideImage() {
    this.imageDisplayTarget.classList.add('d-none')
  }
} 