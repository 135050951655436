import { Controller } from '@hotwired/stimulus'
import flatpickr from "flatpickr";
export default class extends Controller {

  static targets = ["schedulingInputs"]

  connect() {
    $("#schedule_type").bootstrapToggle()
    $("#schedule_type").on("change", this.toggleSchedulingInputs.bind(this))
    flatpickr("#run_at", {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    })
  }

  toggleSchedulingInputs() {
    this.schedulingInputsTarget.hidden = !this.schedulingInputsTarget.hidden
  }
}