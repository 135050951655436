var updateRevenue;

import Bloodhound from 'bloodhound-js';

import autosize from '../../src/autosize';

$(document).on('change', '#booking_meeting_menu_item_menu_item_id', function() {
  var menuItemId;
  menuItemId = $(this).val();
  if ((menuItemId != null) && menuItemId !== "") {
    return $.ajax({
      url: `/menu_items/${menuItemId}.json?booking_meeting_id=${bookingMeetingId}`,
      success: function(data) {
        $('#booking_meeting_menu_item_expense_category').val(data.expense_category);
        $('#booking_meeting_menu_item_expense_category').trigger('change');
        $('#booking_meeting_menu_item_revenue_category').val(data.revenue_category);
        $('#booking_meeting_menu_item_revenue_category').trigger('change');
        $('#booking_meeting_menu_item_name').val(data.name);
        $('#booking_meeting_menu_item_unit').val(data.unit);
        $('#booking_meeting_menu_item_item_cost').val(window.padCurrency(data.item_cost));
        $('#booking_meeting_menu_item_body').val(data.body);
        $('#booking_meeting_menu_item_billing_code').val(data.billing_code);
        $('#booking_meeting_menu_item_service_charge').val(data.service_charge);
        $('#booking_meeting_menu_item_service_charge_2').val(data.service_charge_2);
        $('#booking_meeting_menu_item_do_not_apply_service_charge_2_to_subtotal').val(data.do_not_apply_service_charge_2_to_subtotal);
        $('#booking_meeting_menu_item_do_not_apply_service_charge_2_to_subtotal').prop('checked', data.do_not_apply_service_charge_2_to_subtotal);
        $('#booking_meeting_menu_item_gratuities').val(data.gratuities);
        $('#booking_meeting_menu_item_admin_fee').val(data.admin_fee);
        $('#booking_meeting_menu_item_admin_fee_tax').val(data.admin_fee_tax);
        $('#booking_meeting_menu_item_sales_tax').val(data.sales_tax);
        $('#booking_meeting_menu_item_food_tax').val(data.food_tax);
        $('#booking_meeting_menu_item_beverage_tax').val(data.beverage_tax);
        $('#booking_meeting_menu_item_room_rental_tax').val(data.room_rental_tax);
        $('#booking_meeting_menu_item_service_charge_tax').val(data.service_charge_tax);
        return $('#booking_meeting_menu_item_federal_sales_tax').val(data.federal_sales_tax);
      }
    });
  } else {
    $('#booking_meeting_menu_item_expense_category').val("");
    $('#booking_meeting_menu_item_revenue_category').val("");
    $('#booking_meeting_menu_item_name').val("");
    $('#booking_meeting_menu_item_unit').val("");
    $('#booking_meeting_menu_item_item_cost').val("");
    $('#booking_meeting_menu_item_body').val("");
    $('#booking_meeting_menu_item_billing_code').val("");
    $('#booking_meeting_menu_item_food_tax').val("");
    $('#booking_meeting_menu_item_beverage_tax').val("");
    $('#booking_meeting_menu_item_sales_tax').val("");
    $('#booking_meeting_menu_item_service_charge').val("");
    $('#booking_meeting_menu_item_service_charge_2').val("");
    $('#booking_meeting_menu_item_do_not_apply_service_charge_2_to_subtotal').val(false);
    $('#booking_meeting_menu_item_gratuities').val("");
    $('#booking_meeting_menu_item_admin_fee').val("");
    $('#booking_meeting_menu_item_admin_fee_tax').val("");
    $('#booking_meeting_menu_item_room_rental_tax').val("");
    return $('#booking_meeting_menu_item_service_charge_tax').val("");
  }
});

$(document).on('click', '.save-booking-meeting', function(event) {
  event.preventDefault();
  window.pleaseWait();
  return $('#function-details-form').submit();
});

$(document).on('click', '.save-booking-meeting-and-return', function(event) {
  event.preventDefault();
  window.pleaseWait();
  $('#return_to_booking').val(1);
  return $('#function-details-form').submit();
});

$(document).on('change', '#booking_meeting_visrez_plan_id', function(event) {
  var $selected;
  $selected = $(this).find(':selected');
  if ($selected.data('plan-name')) {
    $('#booking_meeting_visrez_plan_name').val($selected.data('plan-name'));
    $('#booking_meeting_visrez_plan_builder').val($selected.data('plan-builder'));
    $('.visrez-plan-builder').show();
    $('.visrez-plan-builder').attr('href', $selected.data('plan-builder'));
    $('#booking_meeting_visrez_plan_thumbnail').val($selected.data('plan-thumbnail'));
    return $('.visrez-plan-thumbnail').attr('src', $selected.data('plan-thumbnail'));
  } else {
    // when the select is blanked
    $('.visrez-plan-builder').hide();
    $('.visrez-plan-builder').attr('href', '');
    return $('.visrez-plan-thumbnail').attr('src', '/images/blank.gif');
  }
});

$(document).on('change', '.meeting-quick-select', function(event) {
  var target;
  target = `/booking_meetings/${$(this).val()}/edit`;
  if ($('#menu.tab-pane').is(':visible')) {
    target += '?tab=menu';
  }
  return Turbo.visit(target);
});

$(document).on('change', '#booking_meeting_menu_item_menu_category_id', function(event) {
  var menuCategoryId, url;
  menuCategoryId = $(this).val();
  if ((menuCategoryId != null) && menuCategoryId !== "") {
    url = `/menu_items.json?menu_category=${menuCategoryId}`;
    $('#booking_meeting_menu_item_menu_item_id').val(null).trigger('change');
    return $.getJSON(url, function(data) {
      var i, item, len, option;
      $('#booking_meeting_menu_item_menu_item_id').empty();
      for (i = 0, len = data.length; i < len; i++) {
        item = data[i];
        option = `<option value=\"${item.id}\">${item.name}</option>`;
        $('#booking_meeting_menu_item_menu_item_id').append(option);
      }
      return $('#booking_meeting_menu_item_menu_item_id').val('').trigger('change');
    });
  }
});

$(document).on('change', '.copy-menu-items', function(event) {
  var target;
  target = `/booking_meetings/${bookingMeetingId}/copy_menu_items/${$(this).val()}`;
  if ($('#menu.tab-pane').is(':visible')) {
    target += '?tab=menu';
  }
  return Turbo.visit(target);
});

$(document).on('change', '.copy-notes', function(event) {
  var target;
  target = `/booking_meetings/${bookingMeetingId}/copy_notes/${$(this).val()}`;
  if ($('#menu.tab-pane').is(':visible')) {
    target += '?tab=menu';
  }
  return Turbo.visit(target);
});

updateRevenue = function(coversId, checkId, revenueId) {
  return $(document).on('change', `#${coversId}, #${checkId}`, function(event) {
    var check, covers, revenue;
    covers = $(`#${coversId}`).val();
    check = $(`#${checkId}`).val();
    if ($.isNumeric(covers) && $.isNumeric(check)) {
      revenue = (covers * check).toFixed(2);
      return $(`#${revenueId}`).val(revenue);
    }
  });
};

updateRevenue('booking_meeting_forecast_food_covers', 'booking_meeting_forecast_food_check', 'booking_meeting_forecast_food_revenue');

updateRevenue('booking_meeting_forecast_beverage_covers', 'booking_meeting_forecast_beverage_check', 'booking_meeting_forecast_beverage_revenue');

updateRevenue('booking_meeting_forecast_rental_covers', 'booking_meeting_forecast_rental_check', 'booking_meeting_forecast_rental_revenue');

updateRevenue('booking_meeting_forecast_av_covers', 'booking_meeting_forecast_av_check', 'booking_meeting_forecast_av_revenue');

updateRevenue('booking_meeting_forecast_labor_covers', 'booking_meeting_forecast_labor_check', 'booking_meeting_forecast_labor_revenue');

updateRevenue('booking_meeting_forecast_golf_covers', 'booking_meeting_forecast_golf_check', 'booking_meeting_forecast_golf_revenue');

updateRevenue('booking_meeting_forecast_other_covers', 'booking_meeting_forecast_other_check', 'booking_meeting_forecast_other_revenue');

updateRevenue('booking_meeting_forecast_admin_covers', 'booking_meeting_forecast_admin_check', 'booking_meeting_forecast_admin_revenue');

$(document).on('click', '.edit-bmmi', function(event) {
  var $this;
  event.preventDefault();
  $this = $(this);
  $('#edit-bmmi-modal').on('shown.bs.modal', function() {
    return $('#edit-bmmi-modal-iframe').attr('src', $this.attr('href'));
  });
  return $('#edit-bmmi-modal').modal('show');
});

$(document).on('change', '#beo-complete', function(event, state) {
  var checked;
  checked = $(this).prop('checked');
  return $('#booking_meeting_complete').val(checked);
});

$(document).on('change', '#cancelled', function(event, state) {
  var checked;
  checked = $(this).prop('checked');
  return $('#booking_meeting_cancelled').val(checked);
});

$(document).on('change', '#post', function(event, state) {
  var checked;
  checked = $(this).prop('checked');
  return $('#booking_meeting_post').val(checked);
});

$(document).on('change', '#show-on-beo', function(event, state) {
  var checked;
  checked = $(this).prop('checked');
  return $('#booking_meeting_show_on_beo').val(checked);
});

document.addEventListener('turbo:load', function() {
  var functionTypes, handleCurrencyField, s2Options, timepickerOptions;
  if ($('body').hasClass('booking_meetings')) {
    handleCurrencyField = function($field) {
      if ($.isNumeric($field.val())) {
        return $field.val(parseFloat($field.val()).toFixed(2));
      }
    };
    timepickerOptions = {
      timeFormat: timepickerFormat
    };
    $('#booking_meeting_start_time').timepicker(timepickerOptions);
    $('#booking_meeting_end_time').timepicker(timepickerOptions);
    $('#booking_meeting_meeting_start_time').timepicker(timepickerOptions);
    $('#booking_meeting_meeting_end_time').timepicker(timepickerOptions);
    $('#booking_meeting_menu_item_serving_start').timepicker(timepickerOptions);
    $('#booking_meeting_menu_item_serving_end').timepicker(timepickerOptions);
    handleCurrencyField($('#booking_meeting_forecast_food_check'));
    handleCurrencyField($('#booking_meeting_forecast_food_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_beverage_check'));
    handleCurrencyField($('#booking_meeting_forecast_beverage_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_rental_check'));
    handleCurrencyField($('#booking_meeting_forecast_rental_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_av_check'));
    handleCurrencyField($('#booking_meeting_forecast_labor_check'));
    handleCurrencyField($('#booking_meeting_forecast_golf_check'));
    handleCurrencyField($('#booking_meeting_forecast_other_check'));
    handleCurrencyField($('#booking_meeting_forecast_admin_check'));
    handleCurrencyField($('#booking_meeting_forecast_av_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_labor_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_golf_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_other_revenue'));
    handleCurrencyField($('#booking_meeting_forecast_admin_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_food_check'));
    handleCurrencyField($('#booking_meeting_from_detail_food_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_beverage_check'));
    handleCurrencyField($('#booking_meeting_from_detail_beverage_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_rental_check'));
    handleCurrencyField($('#booking_meeting_from_detail_rental_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_av_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_labor_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_golf_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_other_revenue'));
    handleCurrencyField($('#booking_meeting_from_detail_admin_revenue'));
    handleCurrencyField($('#booking_meeting_actual_food_check'));
    handleCurrencyField($('#booking_meeting_actual_food_revenue'));
    handleCurrencyField($('#booking_meeting_actual_beverage_check'));
    handleCurrencyField($('#booking_meeting_actual_beverage_revenue'));
    handleCurrencyField($('#booking_meeting_actual_rental_check'));
    handleCurrencyField($('#booking_meeting_actual_rental_revenue'));
    handleCurrencyField($('#booking_meeting_actual_av_revenue'));
    handleCurrencyField($('#booking_meeting_actual_av_check'));
    handleCurrencyField($('#booking_meeting_actual_labor_check'));
    handleCurrencyField($('#booking_meeting_actual_labor_revenue'));
    handleCurrencyField($('#booking_meeting_actual_golf_check'));
    handleCurrencyField($('#booking_meeting_actual_other_check'));
    handleCurrencyField($('#booking_meeting_actual_other_revenue'));
    handleCurrencyField($('#booking_meeting_actual_admin_revenue'));
    s2Options = {
      width: '100%',
      minimumResultsForSearch: 5,
      placeholder: "Please select",
      allowClear: true
    };
    //$('#booking_meeting_menu_item_menu_item_id').select2(s2Options)
    functionTypes = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      limit: 10,
      prefetch: {
        url: '/function_types.json',
        ttl: 0
      }
    });
    functionTypes.initialize();
    $('input#booking_meeting_function').typeahead({
      hint: false
    }, {
      name: 'functionType',
      displayKey: 'name',
      source: functionTypes.ttAdapter()
    });
    autosize($('#booking_meeting_notes_setup'));
    autosize($('#booking_meeting_notes_av'));
    autosize($('#booking_meeting_notes_menu'));
    autosize($('#booking_meeting_notes_beverage'));
    autosize($('#booking_meeting_notes_breaks'));
    autosize($('#booking_meeting_notes_special'));
    return $('input[data-toggle="toggle"]').bootstrapToggle();
  }
});
