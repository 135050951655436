import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'

export default class extends Controller {
  static classes = ['hidden']
  static targets = ['form', 'input', 'clearButton']
  static values = {
    query: String
  }

  connect() {
    this.handleSearch = throttle(this.handleSearch, 3000).bind(this)
  }

  queryValueChanged(value) {
    if (value.length === 0) {
      this.hideClearButton()
    } else {
      this.showClearButton()
    }
  }

  submit(e) {
    if (e.keyCode === 27) {
      this.inputTarget.value = ''
    }

    this.queryValue = this.inputTarget.value
    this.handleSearch()
  }

  submitOnEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      return false
    }
  }

  handleSearch(_) {
    if (this.queryValue.length == 0) {
      this.fireSearchForm()
      this.dispatch('search-cleared')
    } else if (this.queryValue.length >= 3) {
      this.fireSearchForm()
      this.dispatch('searched')
    }
  }

  clear() {
    this.inputTarget.value = ''
    this.queryValue = this.inputTarget.value
    this.handleSearch()
  }

  fireSearchForm() {
    window.pleaseWait()
    this.formTarget.requestSubmit()
  }

  submitEnd(_) {
    window.unblockUI()
  }

  hideClearButton() {
    this.clearButtonTarget.classList.add(this.hiddenClass)
  }

  showClearButton() {
    this.clearButtonTarget.classList.remove(this.hiddenClass)
  }
}
