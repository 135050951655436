import Rails from '@rails/ujs';

import Bloodhound from 'bloodhound-js';

import moment from 'moment';

import Hogan from 'hogan.js';

import plupload from 'plupload';

import ClipboardJS from 'clipboard';

import flatpickr from 'flatpickr';

window.documentApp = {
  saved: true,
  step: 'general',
  documentId: null,
  startDate: null,
  endDate: null,
  clientId: null,
  getDates: function(startDate, stopDate) {
    var currentDate, dateArray;
    dateArray = new Array();
    currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate));
      currentDate = currentDate.add(1, 'days');
    }
    return dateArray;
  },
  getDateRange: function(startDate, endDate) {
    var d, dates, i, len, range;
    dates = [];
    range = this.getDates(moment(startDate), moment(endDate));
    for (i = 0, len = range.length; i < len; i++) {
      d = range[i];
      dates.push({
        date: d,
        long: d.format('dddd, MMMM Do'),
        human: d.format('M/D'),
        humanWithDay: d.format('ddd<br/>M/D'),
        short: d.format('YYYY-MM-DD')
      });
    }
    return dates;
  },
  keyifyDates: function(start, end) {
    return start + '|' + end;
  },
  blockUI: function() {
    return $.blockUI({
      message: null,
      fadeIn: 100,
      fadeOut: 100,
      overlayCSS: {
        backgroundColor: '#000',
        opacity: .2,
        cursor: 'wait'
      },
      css: {
        border: 'none',
        padding: '10px',
        fontSize: '12px',
        backgroundColor: '#000',
        '-webkit-border-radius': '10px',
        '-moz-border-radius': '10px',
        opacity: .2,
        color: '#fff'
      }
    });
  },
  saveDocument: function(target) {
    var liveedit;
    liveedit = new URLSearchParams(window.location.search).get('liveedit');
    if (liveedit) {
      target += "?liveedit=true";
    }
    tinymce.remove();
    window.documentApp.blockUI();
    $('form.simple_form').one('ajax:complete', function() {
      window.documentApp.handleSavedState(true);
      // sometimes you just gotta do what you gotta do
      if (target.includes('/send') || target.includes('/booking_merges')) {
        window.top.location.href = target.replace('?liveedit=true', '');
      } else {
        Turbo.visit(target);
      }
      return end;
    });
    tinymce.triggerSave();
    return Rails.fire(document.querySelector('form.simple_form'), 'submit');
  },
  handleSavedState: function(saved) {
    var $saveDocumentButton;
    window.documentApp.saved = saved;
    $saveDocumentButton = $('.save-document');
    if (window.documentApp.saved) {
      $saveDocumentButton.addClass('disabled');
      $saveDocumentButton.html('Saved');
      return window.parent.postMessage({
        type: 'save'
      }, '*');
    } else {
      $saveDocumentButton.removeClass('disabled');
      return $saveDocumentButton.html('Save Changes');
    }
  },
  uploadHandler: function(blobInfo, success, failure) {
    var data;
    data = new FormData();
    data.append('file', blobInfo.blob());
    return $.ajax({
      url: '/document_image_uploads',
      type: 'POST',
      data: data,
      cache: false,
      contentType: false,
      processData: false,
      success: function(data) {
        if (data.errors) {
          return failure(data.errors);
        } else {
          return success(data.location);
        }
      }
    });
  },
  initializeTinyMCE: function() {
    var toptions;
    tinymce.remove();
    toptions = Object.assign({}, window.tinyMCEOptions, {
      toolbar: ["bold italic underline forecolor backcolor | bullist numlist | alignleft aligncenter alignright | table | link unlink | image media | pagebreak | code | undo redo"],
      image_list: `/clients/${window.documentApp.clientId}/photos.json`,
      images_upload_handler: window.documentApp.uploadHandler,
      setup: function(ed) {
        return ed.on('change', function(e) {
          return window.documentApp.handleSavedState(false);
        });
      }
    });
    tinymce.init(toptions);
    return window.setTimeout((function() {
      return $('body').scrollTop(0);
    }), 500);
  },
  initializeTinyMCEFull: function() {
    var toptions;
    tinymce.remove();
    toptions = Object.assign({}, window.tinyMCEFullOptions, {
      plugins: 'noneditable,autoresize,advlist,autolink,lists,link,image,charmap,print,preview,searchreplace,visualblocks,code,fullscreen,pagebreak,insertdatetime,media,table,code',
      toolbar: ["insert | undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak removeformat | table | searchreplace | link unlink | image media | code"],
      image_list: `/clients/${window.documentApp.clientId}/photos.json`,
      noneditable_noneditable_class: 'noneditable',
      setup: function(ed) {
        return ed.on('change', function(e) {
          var esignatureId;
          // turn the corresponding "modified" boolean ON
          esignatureId = e.target.id.replace('document_esignatures_attributes_', '').replace('_template_body', '');
          $(`#document_esignatures_attributes_${esignatureId}_modified`).prop('checked', true).change();
          return window.documentApp.handleSavedState(false);
        });
      },
      images_upload_handler: window.documentApp.uploadHandler
    });
    tinymce.init(toptions);
    return window.setTimeout((function() {
      return $('body').scrollTop(0);
    }), 500);
  },
  initializeTinyMCEForSections: function() {
    var toptions;
    tinymce.remove();
    window.suppressPrintCss = true;
    toptions = Object.assign({}, window.tinyMCEFullOptions, {
      toolbar: ["insert | undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak removeformat | table | searchreplace | link unlink | image media | code"],
      image_list: `/clients/${window.documentApp.clientId}/photos.json`,
      plugins: 'autoresize,advlist,autolink,lists,link,image,charmap,print,preview,searchreplace,visualblocks,code,fullscreen,pagebreak,insertdatetime,media,table,code',
      setup: function(ed) {
        return ed.on('change', function(e) {
          return window.documentApp.handleSavedState(false);
        });
      },
      images_upload_handler: window.documentApp.uploadHandler
    });
    tinymce.init(toptions);
    return window.setTimeout((function() {
      return $('body').scrollTop(0);
    }), 500);
  },
  generalStep: {
    startDatePicker: null,
    endDatePicker: null,
    ensureStartDateBeforeEndDate: function() {
      if ($('#document_start_date').val()) {
        return this.endDatePicker.set('minDate', $('#document_start_date').val());
      }
    },
    handleDateSelection: function() {
      var flatpickrOptions;
      flatpickrOptions = {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d',
        onChange: function() {
          var target;
          if ($('body').hasClass('edit')) {
            $('#document_template_changing').val('1');
            target = `/documents/${window.documentApp.documentId}/edit/general`;
            return window.documentApp.saveDocument(target);
          } else {
            return window.documentApp.generalStep.ensureStartDateBeforeEndDate();
          }
        }
      };
      this.startDatePicker = flatpickr('#document_start_date', flatpickrOptions);
      this.endDatePicker = flatpickr('#document_end_date', flatpickrOptions);
      return this.ensureStartDateBeforeEndDate();
    },
    handleCustomerTypeahead: function() {
      var customers, datasets, options, template;
      customers = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('customer_name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/search.json?query=%QUERY',
          wildcard: '%QUERY'
        }
      });
      customers.initialize();
      template = Hogan.compile('<p><strong>{{value}}</strong><br/><span style="font-size:10px">{{customer_contact_name}}<br/>{{customer_contact_email}}</span></p>');
      options = {
        minLength: 3,
        hint: false
      };
      datasets = {
        name: 'customer_name',
        source: customers.ttAdapter(),
        limit: 10,
        displayKey: 'customer_name',
        templates: {
          suggestion: template.render.bind(template)
        }
      };
      $('#document_customer_name').typeahead(options, datasets);
      return $('#document_customer_name').on('typeahead:selected', function(event, datum) {
        $('#document_customer_contact_name').val(datum.customer_contact_name);
        $('#document_customer_contact_title').val(datum.customer_contact_title);
        $('#document_customer_contact_email').val(datum.customer_contact_email);
        $('#document_customer_contact_address').val(datum.customer_contact_address);
        return $('#document_customer_contact_phone').val(datum.customer_contact_phone);
      });
    },
    handleSignerSorting: function() {
      return $('tbody.signer-sortable').sortable({
        helper: window.sortableFixHelper,
        scroll: false,
        stop: function(event, ui) {
          var i, index, len, ref, results, table, tr;
          window.documentApp.handleSavedState(false);
          table = ui.item.parents('table');
          ref = $(table).find('tr.nested-fields:visible');
          results = [];
          for (index = i = 0, len = ref.length; i < len; index = ++i) {
            tr = ref[index];
            results.push($(tr).find('.document-signer-position').val(index + 1));
          }
          return results;
        }
      });
    }
  },
  additionalDatesStep: {
    changed: function() {
      return this.handleDateSelection();
    },
    handleDateSelection: function() {
      var dateOptions;
      dateOptions = {
        buttonText: "<i class='fa fa-calendar-o'></i>",
        changeMonth: true,
        changeYear: true,
        dateFormat: 'yy-mm-dd',
        showOn: 'both'
      };
      $('.additional-date-start').datepicker($.extend({}, dateOptions, {
        onSelect: function(selectedDate) {
          var $endDateElement;
          $(this).siblings('.pick-start-date').html(window.formatDate(selectedDate));
          $endDateElement = $(this).parent().parent().find('.additional-date-end');
          $endDateElement.datepicker('option', 'minDate', selectedDate);
          $endDateElement.datepicker('setDate', selectedDate);
          $endDateElement.siblings('.pick-end-date').html(window.formatDate(selectedDate));
          return window.documentApp.handleSavedState(false);
        }
      }));
      return $('.additional-date-end').datepicker($.extend({}, dateOptions, {
        onSelect: function(selectedDate) {
          $(this).siblings('.pick-end-date').html(window.formatDate(selectedDate));
          return window.documentApp.handleSavedState(false);
        }
      }));
    }
  },
  sectionsStep: {
    handleMultiselect: function() {
      var selected;
      $('#sections').multiselect({
        buttonClass: 'btn btn-primary btn-xs',
        nonSelectedText: 'Sections',
        includeSelectAllOption: true,
        dropRight: true,
        buttonText: function(options, select) {
          return `Sections (${options.length})`;
        },
        onChange: function(option, checked, select) {
          var $sectionElement, sectionId;
          $('#document_included_sections').val($('#sections').val().join(','));
          sectionId = $(option).val();
          $sectionElement = $(`.section-${sectionId}`);
          return $sectionElement.toggle(checked);
        },
        onSelectAll: function() {
          $('#document_included_sections').val($('#sections').val().join(','));
          return $('.section-wrapper').show();
        },
        onDeselectAll: function() {
          $('#document_included_sections').val('');
          return $('.section-wrapper').hide();
        }
      });
      selected = $('#document_included_sections').val().split(',');
      return $('#sections').multiselect('select', selected);
    }
  },
  roomBlockStep: {
    handleOneRateForAllDates: function() {
      var $element, $rateElements, element, i, len, occupancies, occupancy, oneRateElement, rate, results, roomTypeId;
      occupancies = ['', 'single-', 'double-', 'triple-', 'quad-'];
      results = [];
      for (i = 0, len = occupancies.length; i < len; i++) {
        occupancy = occupancies[i];
        oneRateElement = $(`input.one-${occupancy}rate-for-all-dates`);
        results.push((function() {
          var j, k, len1, len2, results1;
          results1 = [];
          for (j = 0, len1 = oneRateElement.length; j < len1; j++) {
            element = oneRateElement[j];
            $element = $(element);
            roomTypeId = $element.data('room-type-id');
            $rateElements = $(`input.${occupancy}rate-for-room-type-` + roomTypeId);
            rate = "";
            for (k = 0, len2 = $rateElements.length; k < len2; k++) {
              element = $rateElements[k];
              if ($(element).val() !== "") {
                rate = $(element).val();
                break;
              }
            }
            results1.push($element.val(rate));
          }
          return results1;
        })());
      }
      return results;
    },
    manageDailyRateVisibility: function() {
      if ($('input#document_use_daily_rate').val() === 'true') {
        $('.daily-rate').show();
        $('.no-daily-rate').hide();
        if ((window.roomTypeCategoryId != null) && window.roomTypeCategoryId !== 'All') {
          $('.daily-rate.category').hide();
          return $('.daily-rate.category-' + window.roomTypeCategoryId).show();
        }
      } else {
        $('.daily-rate').hide();
        return $('.no-daily-rate').show();
      }
    },
    manageUpsellVisibility: function() {
      if ($('input#document_upsell').val() === 'true') {
        return $('.rate-only').show();
      } else {
        $('.rate-only').hide();
        $('input.upsell-checkbox').prop('checked', false);
        return $('input.quantity').prop('disabled', false);
      }
    }
  },
  meetingSpaceStep: {
    extractDataFromRow: function(row) {
      return {
        date: row.find("input.meeting-date").val(),
        start: row.find("input.meeting-start").val(),
        end: row.find("input.meeting-end").val(),
        function: row.find("input.meeting-function").val(),
        room: row.find("input.meeting-room[id^=document_meetings_attributes_]").val(),
        number_setup: row.find("input.meeting-number-setup").val(),
        rental: row.find("input.meeting-rental").val(),
        fbmin: row.find("input.meeting-fbmin").val(),
        notes: row.find("input.meeting-notes").val(),
        visual_url: row.find("input.meeting-visual-url").val(),
        visrez_plan_id: row.find("select.meeting-visrez-plan-id").val(),
        meeting_room_id: row.find("input.meeting-meeting-room-id").val(),
        client_venue_id: row.find("input.meeting-client-venue-id").val(),
        location: row.find("input.meeting-location").val()
      };
    },
    sorting: function() {
      return $('tbody.sortable').sortable({
        helper: window.sortableFixHelper,
        scroll: false,
        connectWith: '.connected',
        items: '>*:not(.nosort)',
        stop: function(event, ui) {
          return window.documentApp.meetingSpaceStep.sorted(ui.item);
        }
      });
    },
    sorted: function(item) {
      var parentTable;
      window.documentApp.handleSavedState(false);
      parentTable = item.parents('table');
      item.find('.meeting-date').val(parentTable.data('date'));
      return this.changed();
    },
    changed: function() {
      this.managePosition();
      this.fillCopyFromDropdowns();
      this.configureTypeahead();
      this.configureFunctionTypeTypeahead();
      this.configureLocationTypeahead();
      return this.configureTimepicker();
    },
    managePosition: function() {
      var i, index, len, ref, results, table, tr;
      ref = $('table.meeting-space-edit');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        table = ref[i];
        results.push((function() {
          var j, len1, ref1, results1;
          ref1 = $(table).find('tr.nested-fields:visible');
          results1 = [];
          for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
            tr = ref1[index];
            results1.push($(tr).find('.meeting-position').val(index + 1));
          }
          return results1;
        })());
      }
      return results;
    },
    datesWithMeetings: function() {
      var dates, i, len, ref, table;
      dates = [];
      ref = $('table.meeting-space-edit');
      for (i = 0, len = ref.length; i < len; i++) {
        table = ref[i];
        if ($(table).find('tr.nested-fields:visible').length > 0) {
          dates = dates.concat($(table).data('date'));
        }
      }
      return dates;
    },
    otherDatesWithMeetings: function(date) {
      var dates, index;
      dates = this.datesWithMeetings();
      index = dates.indexOf(date);
      if (index > -1) {
        dates.splice(index, 1);
      }
      return dates;
    },
    fillCopyFromDropdowns: function() {
      var $ul, button, date, dates, i, len, ref, results, thisDate, ul;
      ref = $('.copy-from.dropdown-menu');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        ul = ref[i];
        $ul = $(ul);
        thisDate = $ul.data('date');
        $ul.empty();
        dates = this.otherDatesWithMeetings(thisDate);
        button = $ul.parent('.btn-group');
        if (dates.length > 0) {
          button.show();
          results.push((function() {
            var j, len1, results1;
            results1 = [];
            for (j = 0, len1 = dates.length; j < len1; j++) {
              date = dates[j];
              results1.push($ul.append(`<li><a href=\"#\" class=\"do-copy\" data-from=\"${date}\" data-to=\"${thisDate}\">${moment(date).format('dddd, MMMM D')}</a></li>`));
            }
            return results1;
          })());
        } else {
          results.push(button.hide());
        }
      }
      return results;
    },
    duplicateRow: function(row, newDate) {
      var $markup, domPrefix, newId, rowData, template;
      rowData = window.documentApp.meetingSpaceStep.extractDataFromRow(row);
      template = $(`.add-fields-for-${rowData.date}`).data('association-insertion-template');
      newId = new Date().getTime() + Math.floor(Math.random() * 1000);
      $markup = $(template.replace(/new_meetings/g, newId));
      domPrefix = `#document_meetings_attributes_${newId}`;
      $(`${domPrefix}_date`, $markup).val(newDate || rowData.date);
      $(`${domPrefix}_start`, $markup).val(rowData.start);
      $(`${domPrefix}_end`, $markup).val(rowData.end);
      $(`${domPrefix}_function`, $markup).val(rowData.function);
      $(`${domPrefix}_room`, $markup).val(rowData.room);
      $(`${domPrefix}_meeting_room_id`, $markup).val(rowData.meeting_room_id);
      $(`${domPrefix}_number_setup`, $markup).val(rowData.number_setup);
      $(`${domPrefix}_rental`, $markup).val(rowData.rental);
      $(`${domPrefix}_fbmin`, $markup).val(rowData.fbmin);
      $(`${domPrefix}_notes`, $markup).val(rowData.notes);
      $(`${domPrefix}_visual_url`, $markup).val(rowData.visual_url);
      $(`${domPrefix}_visrez_plan_id`, $markup).val(rowData.visrez_plan_id);
      $(`${domPrefix}_client_venue_id`, $markup).val(rowData.client_venue_id);
      $(`${domPrefix}_location`, $markup).val(rowData.location);
      return $markup;
    },
    configureTypeahead: function() {
      var rooms, template, typeaheadOptions;
      rooms = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
          url: '/meeting_rooms.json',
          ttl: 0
        }
      });
      rooms.initialize();
      typeaheadOptions = {
        name: 'room',
        displayKey: 'name',
        source: rooms.ttAdapter(),
        limit: 50
      };
      if (window.documentApp.multiProperty) {
        template = Hogan.compile('<p><span style="color:#aaa">{{property}}</span><br><strong>{{title}}</strong></p>');
        typeaheadOptions = $.extend(typeaheadOptions, {
          templates: {
            suggestion: template.render.bind(template)
          }
        });
      }
      $('input.meeting-room:not(.tt-input,.tt-hint)').typeahead({
        hint: false
      }, typeaheadOptions);
      return $('input.meeting-room').on('typeahead:selected', function(event, datum) {
        $(this).closest('tr').find('.meeting-meeting-room-id').val(datum.id);
        return window.documentApp.handleSavedState(false);
      });
    },
    configureFunctionTypeTypeahead: function() {
      var functionTypes;
      functionTypes = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
          url: '/function_types.json',
          ttl: 0
        }
      });
      functionTypes.initialize();
      $('input.meeting-function:not(.tt-input,.tt-hint)').typeahead({
        hint: false
      }, {
        name: 'functionType',
        displayKey: 'name',
        source: functionTypes.ttAdapter(),
        limit: 50
      });
      return $('input.meeting-function').on('typeahead:selected', function(event, datum) {
        return window.documentApp.handleSavedState(false);
      });
    },
    configureLocationTypeahead: function() {
      var locations;
      locations = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
          url: '/client_venues.json',
          ttl: 0
        }
      });
      locations.initialize();
      $('input.meeting-location:not(.tt-input,.tt-hint)').typeahead({
        hint: false
      }, {
        name: 'location',
        displayKey: 'name',
        source: locations.ttAdapter(),
        limit: 50
      });
      return $('input.meeting-location').on('typeahead:selected', function(event, datum) {
        return window.documentApp.handleSavedState(false);
      });
    },
    configureTimepicker: function() {
      var timepickerOptions;
      timepickerOptions = {
        timeFormat: timepickerFormat
      };
      $('.meeting-start').timepicker(timepickerOptions);
      return $('.meeting-end').timepicker(timepickerOptions);
    }
  },
  golfStep: {
    handleDateSelection: function() {
      var flatpickrOptions;
      flatpickrOptions = {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d'
      };
      return flatpickr('.golf-date', flatpickrOptions);
    }
  },
  cateringWorksheetStep: {
    sorting: function() {
      return $('.dci-sortable').sortable({
        helper: window.sortableFixHelper,
        scroll: false,
        update: () => {
          return $.post(`/documents/${window.documentApp.documentId}/document_catering_items/sort`, '_method=put&' + $('.dci-sortable').sortable('serialize'));
        }
      });
    },
    clearFields: function() {
      $('#document_catering_item_name').val("");
      $('#document_catering_item_unit').val("");
      $('#document_catering_item_item_cost').val("");
      $('#document_catering_item_notes').val("");
      $('#document_catering_item_food_tax').val("");
      $('#document_catering_item_beverage_tax').val("");
      $('#document_catering_item_sales_tax').val("");
      $('#document_catering_item_federal_sales_tax').val("");
      $('#document_catering_item_service_charge').val("");
      $('#document_catering_item_gratuities').val("");
      $('#document_catering_item_room_rental_tax').val("");
      $('#document_catering_item_time').val("");
      $('#document_catering_item_function').val("");
      $('#document_catering_item_location').val("");
      $('#document_catering_item_date').val("").trigger("change");
      $('#document_catering_item_menu_item_id').val("").trigger("change");
      return $('#document_catering_item_menu_category_id').val("").trigger("change");
    },
    configureTypeahead: function() {
      var rooms;
      rooms = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
          url: '/meeting_rooms.json',
          ttl: 0
        }
      });
      rooms.initialize();
      return $('#document_catering_item_location').typeahead({
        hint: false
      }, {
        name: 'room',
        displayKey: 'name',
        source: rooms.ttAdapter(),
        limit: 50
      });
    }
  },
  contractStep: {
    handleDateSelection: function() {
      var flatpickrOptions;
      flatpickrOptions = {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d'
      };
      flatpickr('#document_contract_due', flatpickrOptions);
      return flatpickr('#document_cut_off_date', flatpickrOptions);
    }
  },
  mediaStep: {
    initializeTooltips: function() {
      return $('[data-toggle="tooltip"]').tooltip();
    },
    loadCurrentPhotos: function() {
      var i, len, photo, photoData, photoDataString, results;
      photoDataString = $('#document_photo_data').val();
      if (photoDataString !== '') {
        photoData = JSON.parse(photoDataString);
        results = [];
        for (i = 0, len = photoData.length; i < len; i++) {
          photo = photoData[i];
          results.push(this.renderPhoto(photo));
        }
        return results;
      }
    },
    renderPhoto: function(photo) {
      var markup, photoTemplate;
      photoTemplate = Hogan.compile($('#current-photo-template').html());
      markup = photoTemplate.render(photo);
      $('ul.media-list.current').append(markup);
      return this.inputChanged();
    },
    manageSiteTourVisibility: function() {
      if ($('input#document_media_type').val() === 'photos') {
        $('div.photos').show();
        return $('div.site-tour').hide();
      } else {
        $('div.photos').hide();
        return $('div.site-tour').show();
      }
    },
    sorting: function() {
      return $('ul.media-sortable').sortable({
        helper: window.sortableFixHelper,
        scroll: false,
        stop: () => {
          this.inputChanged();
          return window.documentApp.handleSavedState(false);
        }
      });
    },
    inputChanged: function() {
      var $photoList, data, i, len, photo, photos;
      $photoList = $('ul.media-list.current li img.photo');
      photos = [];
      for (i = 0, len = $photoList.length; i < len; i++) {
        photo = $photoList[i];
        data = $(photo).data();
        photos.push({
          id: data.id,
          smallThumb: photo.src
        });
      }
      return $('#document_photo_data').val(JSON.stringify(photos));
    },
    addPhoto: function(photo) {
      var currentPhoto, currentPhotos, exists, i, len;
      exists = false;
      currentPhotos = JSON.parse($('#document_photo_data').val());
      for (i = 0, len = currentPhotos.length; i < len; i++) {
        currentPhoto = currentPhotos[i];
        if (currentPhoto.id === photo.id) {
          exists = true;
          break;
        }
      }
      if (!exists) {
        this.renderPhoto(photo);
        this.sorting();
        return window.documentApp.handleSavedState(false);
      }
    }
  },
  linksStep: {
    handleUpload: function() {
      var attachmentUploadUrl, attachmentUploader;
      attachmentUploadUrl = `/documents/${window.documentApp.documentId}/attachments`;
      attachmentUploader = new plupload.Uploader({
        browse_button: 'attachment-add-files',
        url: attachmentUploadUrl
      });
      attachmentUploader.init();
      attachmentUploader.bind('FilesAdded', function() {
        $('#upload-attachments-button').hide();
        $('#upload-attachments-progress').show();
        return attachmentUploader.start();
      });
      return attachmentUploader.bind('UploadComplete', function() {
        // $('#upload-attachments-button').show()
        // $('#upload-attachments-progress').hide()
        // $.get attachmentUploadUrl, null, 'script'
        return window.documentApp.saveDocument(window.location.pathname);
      });
    }
  },
  sendStep: {
    handleCopyToClipboard: function() {
      var clipboard;
      clipboard = new ClipboardJS('.copy-to-clipboard');
      return clipboard.on('success', function(event) {
        var $clipButton, cb;
        $clipButton = $(event.trigger);
        $clipButton.tooltip({
          title: 'Copied!'
        });
        $clipButton.tooltip('show');
        cb = function(elem) {
          return elem.tooltip('destroy');
        };
        return setTimeout((function() {
          return cb($clipButton);
        }), 1500);
      });
    }
  },
  handleDocumentEdit: function() {
    var self;
    $('input[data-toggle="toggle"]').bootstrapToggle();
    self = this;
    switch (self.step) {
      case 'general':
        self.generalStep.handleDateSelection();
        self.generalStep.handleSignerSorting();
        return self.initializeTinyMCE();
      case 'additional_dates':
        return self.additionalDatesStep.handleDateSelection();
      case 'text':
        return self.initializeTinyMCE();
      case 'sections':
        self.sectionsStep.handleMultiselect();
        return self.initializeTinyMCEForSections();
      case 'room_block':
        self.roomBlockStep.handleOneRateForAllDates();
        self.roomBlockStep.manageUpsellVisibility();
        self.roomBlockStep.manageDailyRateVisibility();
        return self.initializeTinyMCE();
      case 'all_inclusive':
        return self.initializeTinyMCE();
      case 'meeting_space':
        self.meetingSpaceStep.sorting();
        self.meetingSpaceStep.fillCopyFromDropdowns();
        self.meetingSpaceStep.configureTypeahead();
        self.meetingSpaceStep.configureFunctionTypeTypeahead();
        self.meetingSpaceStep.configureLocationTypeahead();
        self.meetingSpaceStep.configureTimepicker();
        return self.initializeTinyMCE();
      case 'golf':
        self.initializeTinyMCE();
        return self.golfStep.handleDateSelection();
      case 'catering_worksheet':
        self.initializeTinyMCE();
        self.cateringWorksheetStep.sorting();
        return self.cateringWorksheetStep.configureTypeahead();
      case 'contract':
        self.contractStep.handleDateSelection();
        return self.initializeTinyMCE();
      case 'merge_contract':
        return self.initializeTinyMCEFull();
      case 'media':
        self.mediaStep.initializeTooltips();
        self.mediaStep.loadCurrentPhotos();
        self.mediaStep.manageSiteTourVisibility();
        return self.mediaStep.sorting();
      case 'links':
        return self.linksStep.handleUpload();
      case 'send':
        return self.sendStep.handleCopyToClipboard();
    }
  }
};

//# Event Handlers

// handle document edit mode toggle
$(document).on('click', '.document-edit-mode-toggle', function(event) {
  event.preventDefault();
  return window.top.location.href = $(this).attr('href');
});

// step navigation
$(document).on('click', '.steps li a, a.send-step', function(event) {
  var $this, step, target;
  event.preventDefault();
  $this = $(this);
  step = $this.data('step');
  window.documentApp.step = step;
  target = $this.attr('href');
  return window.documentApp.saveDocument(target);
});

// handle saved state
$(document).on('input change propertychange paste', 'body.documents.edit input, body.documents.edit textarea', function() {
  return window.documentApp.handleSavedState(false);
});

$(document).on('change', 'body.documents.edit select', function() {
  return window.documentApp.handleSavedState(false);
});

$(document).on('page:change', function() {
  return $('#document-meetings').on('cocoon:after-insert cocoon:after-remove', function() {
    window.documentApp.handleSavedState(false);
    return window.documentApp.meetingSpaceStep.changed();
  });
});

$(document).on('page:change', function() {
  return $('.additional-dates').on('cocoon:after-insert cocoon:after-remove', function() {
    window.documentApp.handleSavedState(false);
    return window.documentApp.additionalDatesStep.changed();
  });
});

// handle save changes button
$(document).on('click', '.save-document', function(event) {
  var target;
  event.preventDefault();
  target = window.location.pathname;
  return window.documentApp.saveDocument(target);
});

$(document).on('click', '.save-account', function(event) {
  var target;
  event.preventDefault();
  target = `/documents/${window.documentApp.documentId}/edit/`;
  return window.documentApp.saveDocument(target);
});

// preview button
$(document).on('click', 'a#preview-button', function(event) {
  var target;
  event.preventDefault();
  target = `/documents/${window.documentApp.documentId}/preview`;
  return window.documentApp.saveDocument(target);
});

// view generated contract preview button
$(document).on('click', 'a#preview-contract-button', function(event) {
  var target;
  event.preventDefault();
  target = `/documents/${window.documentApp.documentId}/preview?contract=true`;
  return window.documentApp.saveDocument(target);
});

// document index: copy document
$(document).on('click', 'a.copy-document', function(event) {
  event.preventDefault();
  $('div#copy-modal div.modal-body iframe').attr('src', this.href);
  return $('div#copy-modal').modal('show');
});

// document index: change status
$(document).on('click', 'a.change-status', function(event) {
  event.preventDefault();
  $('div#status-modal div.modal-body iframe').attr('src', this.href);
  return $('div#status-modal').modal();
});

// general: template change
$(document).on('change', '#document_template_id', function(event) {
  var target;
  $('#document_template_changing').val('1');
  target = `/documents/${window.documentApp.documentId}/edit/general`;
  return window.documentApp.saveDocument(target);
});

// general: clearbit lookup
$(document).on('click', '.clearbit-lookup', function(event) {
  event.preventDefault();
  clearbitBlockUI();
  return $.post('/clearbit_calls', {
    q: $('#document_customer_contact_email').val(),
    format: 'json'
  }, function(data) {
    $.unblockUI();
    if (data.company) {
      $("#document_customer_name").val(data.company.name);
      if (data.company.domain) {
        $("#document_logo_path").val(`https://logo.clearbit.com/${data.company.domain}`);
      }
    }
    if (data.person) {
      $("#document_customer_contact_name").val(data.person.name.fullName);
      if (data.person.employment && data.person.employment.title) {
        return $("#document_customer_contact_title").val(data.person.employment.title);
      }
    }
  });
});

// general: user change
$(document).on('change', '#document_user_id', function(event) {
  var target;
  target = `/documents/${window.documentApp.documentId}/edit/general`;
  return window.documentApp.saveDocument(target);
});

// general: word proposal change
$(document).on('change', 'input#document_word_proposal', function(event) {
  var fileName;
  fileName = event.target.value.split('\\').pop();
  $('.word-proposal-upload').css('width', '400px');
  return $('.word-proposal-upload span').html(fileName);
});

// general: logo change
$(document).on('change', 'input#document_logo', function(event) {
  var target;
  target = `/documents/${window.documentApp.documentId}/edit/general`;
  return window.documentApp.saveDocument(target);
});

// general: logo remove
$(document).on('click', 'a.remove-logo', function(event) {
  var target;
  event.preventDefault();
  $('input#document_remove_logo').val('1');
  target = `/documents/${window.documentApp.documentId}/edit/general`;
  return window.documentApp.saveDocument(target);
});

// general: find logo
$(document).on('click', 'a.find-logo', function(event) {
  var target;
  event.preventDefault();
  $('input#document_find_logo').val('1');
  target = `/documents/${window.documentApp.documentId}/edit/general`;
  return window.documentApp.saveDocument(target);
});

// general: email validation
$(document).on('click', '#validate-email-button', function(event) {
  event.preventDefault();
  return $.post('/email_validations', {
    email: $('#document_customer_contact_email').val()
  }, 'script');
});

// general: add a signer
$(document).on('click', '.add-a-signer', function(event) {
  return window.documentApp.handleSavedState(false);
});

// general: internal notes received by timestamp 
$(document).on('blur', '#document_document_internal_note_attributes_received_by', function(event) {
  var $receivedAt;
  $receivedAt = $('#document_document_internal_note_attributes_received_at');
  if (!$receivedAt.val().length) {
    return $receivedAt.val(moment());
  }
});

// additional dates: datepicking
$(document).on('click', 'body.documents.edit .add-additional-date-range', function(event) {
  event.preventDefault();
  return window.documentApp.additionalDatesStep.changed();
});

$(document).on('click', 'body.documents.edit .pick-start-date', function(event) {
  event.preventDefault();
  return $(this).siblings('.additional-date-start').datepicker('show');
});

$(document).on('click', 'body.documents.edit .pick-end-date', function(event) {
  event.preventDefault();
  return $(this).siblings('.additional-date-end').datepicker('show');
});

// text: selling points
$(document).on('click', 'a.insert-selling-points', function(event) {
  var pointsText, text;
  event.preventDefault();
  pointsText = "";
  $('label.selling-point').each(function() {
    if ($(this).find('input:checked').length > 0) {
      if (sellingPointsModalMethod === 'paragraph') {
        return pointsText += $(this).find('span').html() + '<br/><br/>';
      } else {
        return pointsText += '<li>' + $(this).find('span').html() + '</li>';
      }
    }
  });
  text = '';
  if ($('#selling_points_title').val().length > 0) {
    text += '<p><strong>' + $('#selling_points_title').val() + '</strong></p>';
  }
  if (pointsText.length > 0) {
    if (sellingPointsModalMethod === 'paragraph') {
      text += pointsText;
    } else {
      text += '<ul>' + pointsText + '</ul>';
    }
  }
  tinymce.activeEditor.execCommand('mceInsertContent', false, text);
  return $('#selling-points-modal').modal('hide');
});

// room block: one rate for all dates
$(document).on('input propertychange keyup', 'input.one-rate-for-all-dates', function(event) {
  var $this;
  $this = $(this);
  return $('input.rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.one-single-rate-for-all-dates', function(event) {
  var $this;
  $this = $(this);
  return $('input.single-rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.one-double-rate-for-all-dates', function(event) {
  var $this;
  $this = $(this);
  return $('input.double-rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.one-triple-rate-for-all-dates', function(event) {
  var $this;
  $this = $(this);
  return $('input.triple-rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.one-quad-rate-for-all-dates', function(event) {
  var $this;
  $this = $(this);
  return $('input.quad-rate-for-room-type-' + $this.data('room-type-id')).val($this.val());
});

$(document).on('input propertychange keyup', 'input.quantity', function(event) {
  var $this, date, rate, roomTypeId;
  if ($('#document_use_daily_rate').val() !== 'true') {
    $this = $(this);
    roomTypeId = $this.data('room-type-id');
    date = $this.data('date');
    rate = $(`#rate_for_room_type_${roomTypeId}`).val();
    return $(`#document_room_data_room_type_${roomTypeId}_${date}_rate`).val(rate);
  }
});

// room block: category navigation
$(document).on('click', 'ul.room-types-nav li a', function(event) {
  var $this, category;
  $this = $(this);
  category = $this.attr('rel');
  window.roomTypeCategoryId = category;
  event.preventDefault();
  $('ul.room-types-nav li a').removeClass('current');
  $this.addClass('current');
  if (category === 'All') {
    $('tr.category').show();
  } else {
    $('tr.category').hide();
    $('tr.category-' + category).show();
  }
  return window.documentApp.roomBlockStep.manageDailyRateVisibility();
});

// room block: use room block grid
$(document).on('change', '#use-room-block', function(event, state) {
  var $this, $useBlock, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $useBlock = $('input#document_use_block');
  $useBlock.val(checked ? '1' : '0');
  if (checked) {
    $('div.room-block-grid').show();
    return $('div.room-block-stats').hide();
  } else {
    $('div.room-block-grid').hide();
    return $('div.room-block-stats').show();
  }
});

// room block: rate only option changed
$(document).on('change', '#use-upsell', function(event, state) {
  var $this, $useUpsell, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $useUpsell = $('input#document_upsell');
  $useUpsell.val(checked);
  return window.documentApp.roomBlockStep.manageUpsellVisibility();
});

// room block: upsell checkbox clicked
$(document).on('click', 'input.upsell-checkbox', function(event) {
  var $quantityFields, $this, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $quantityFields = $('input.quantity-for-' + $this.data('room-type-id'));
  if (checked) {
    return $quantityFields.prop('disabled', true);
  } else {
    return $quantityFields.prop('disabled', false);
  }
});

// room block: daily rate option changed
$(document).on('change', '#use-daily-rate', function(event, state) {
  var $this, $useDailyRate, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $useDailyRate = $('input#document_use_daily_rate');
  $useDailyRate.val(checked);
  return window.documentApp.roomBlockStep.manageDailyRateVisibility();
});

// room block: occupancy-based rate option changed
$(document).on('change', '#occupancy-based-rate', function(event, state) {
  var $this, $useOccupancyBasedRate, checked, target;
  $this = $(this);
  checked = $this.prop('checked');
  $useOccupancyBasedRate = $('input#document_occupancy_based_rate');
  $useOccupancyBasedRate.val(checked);
  target = `/documents/${window.documentApp.documentId}/edit/room_block`;
  window.documentApp.blockUI();
  return window.documentApp.saveDocument(target);
});

// meeting space: add a meeting
$(document).on('click', 'a.add-a-meeting', function(event) {
  // mostly handled by cocoon
  return window.documentApp.meetingSpaceStep.changed();
});

// meeting space: row duplication
$(document).on('click', 'a.duplicate-row', function(event) {
  var $markup, $original;
  event.preventDefault();
  $original = $(this).closest('tr');
  $markup = window.documentApp.meetingSpaceStep.duplicateRow($original);
  $original.after($markup);
  window.documentApp.meetingSpaceStep.changed();
  return window.documentApp.handleSavedState(false);
});

// meeting space: copy from
$(document).on('click', 'a.do-copy', function(event) {
  var $fromTable, $markup, $toTable, fromDate, i, j, len, len1, ref, ref1, removeLink, toDate, tr;
  event.preventDefault();
  toDate = $(this).data('to');
  fromDate = $(this).data('from');
  $toTable = $(`.meetings-for-${toDate} tbody`);
  $fromTable = $(`.meetings-for-${fromDate} tbody`);
  ref = $toTable.find('a.remove_fields');
  for (i = 0, len = ref.length; i < len; i++) {
    removeLink = ref[i];
    $(removeLink).trigger('click');
  }
  ref1 = $fromTable.find('tr.nested-fields:visible');
  for (j = 0, len1 = ref1.length; j < len1; j++) {
    tr = ref1[j];
    $markup = window.documentApp.meetingSpaceStep.duplicateRow($(tr), toDate);
    $toTable.append($markup);
  }
  window.documentApp.meetingSpaceStep.changed();
  return window.documentApp.handleSavedState(false);
});

// meeting space: show/hide meeting space
$(document).on('change', '#use-meeting-space', function(event, state) {
  var $this, $useMeetingSpace, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $useMeetingSpace = $('input#document_use_meeting_space');
  $useMeetingSpace.val(checked ? '1' : '0');
  if (checked) {
    $('div.meeting-space-grid').show();
    $('div.meeting-space-upload').hide();
  } else {
    $('div.meeting-space-grid').hide();
    $('div.meeting-space-upload').show();
  }
  return window.documentApp.handleSavedState(false);
});

// meeting space: file field
$(document).on('change', 'input#document_meeting_space_file, input#document_meeting_space_file_2', function(event) {
  var target;
  target = `/documents/${window.documentApp.documentId}/edit/meeting_space`;
  window.documentApp.blockUI();
  return window.documentApp.saveDocument(target);
});

// meeting space: remove file field
$(document).on('click', 'a.remove-meeting-space-file', function(event) {
  var target;
  event.preventDefault();
  $('input#document_remove_meeting_space_file').val('1');
  target = `/documents/${window.documentApp.documentId}/edit/meeting_space`;
  window.documentApp.blockUI();
  return window.documentApp.saveDocument(target);
});

// meeting space: remove file field 2
$(document).on('click', 'a.remove-meeting-space-file-2', function(event) {
  var target;
  event.preventDefault();
  $('input#document_remove_meeting_space_file_2').val('1');
  target = `/documents/${window.documentApp.documentId}/edit/meeting_space`;
  window.documentApp.blockUI();
  return window.documentApp.saveDocument(target);
});

// meeting space: new location button
$(document).on('click', 'a.new-location-button', function(event) {
  var target;
  event.preventDefault();
  target = $(this).attr('href');
  window.documentApp.blockUI();
  return window.documentApp.saveDocument(target);
});

// meeting space: selects a visrez plan
$(document).on('change', 'select.meeting-visrez-plan-id', function(event) {
  var $meetingVisrezPlanBuilderInput, $meetingVisrezPlanIdInput, $meetingVisrezPlanNameInput, $meetingVisrezPlanThumbnailInput, $visualUrlInput, selected;
  $visualUrlInput = $(this).closest('tr').find('.meeting-visual-url');
  $meetingVisrezPlanIdInput = $(this).closest('tr').find('.meeting-visrez-plan-id');
  $meetingVisrezPlanNameInput = $(this).closest('tr').find('.meeting-visrez-plan-name');
  $meetingVisrezPlanBuilderInput = $(this).closest('tr').find('.meeting-visrez-plan-builder');
  $meetingVisrezPlanThumbnailInput = $(this).closest('tr').find('.meeting-visrez-plan-thumbnail');
  selected = $(this).find("option:selected");
  if (selected.data('slug')) {
    console.log(selected.data('id'));
    $visualUrlInput.val(`https://build.testing.visrez.com/share/${selected.data('slug')}`);
    $meetingVisrezPlanIdInput.val(selected.data('id'));
    $meetingVisrezPlanNameInput.val(selected.data('name'));
    $meetingVisrezPlanBuilderInput.val(selected.data('builder'));
    return $meetingVisrezPlanThumbnailInput.val(selected.data('thumbnail'));
  } else {
    $visualUrlInput.val('');
    $meetingVisrezPlanIdInput.val('');
    $meetingVisrezPlanNameInput.val('');
    $meetingVisrezPlanBuilderInput.val('');
    return $meetingVisrezPlanThumbnailInput.val('');
  }
});

// meeting space: clicks "create builder" button
$(document).on('click', 'a.visrez-create-builder', function(event) {
  var target;
  event.preventDefault();
  window.documentApp.blockUI();
  target = $(this).attr('href');
  return window.documentApp.saveDocument(target);
});

// availability chart: add proposal button
// $(document).on 'click', '#add-proposal-button', (event) ->
// event.preventDefault()
// $('#proposal-modal .modal-title').html('Add Venue to Chart')
// $('#proposal-modal .modal-body').load $(this).data('href')
// $('#proposal-modal').modal('show')
// Turbo.visit $(this).data('href')

// availability chart: edit proposal button
$(document).on('click', '.edit-proposal', function(event) {
  event.preventDefault();
  // $('#proposal-modal .modal-title').html($(this).data('name'))
  // $('#proposal-modal .modal-body').load $(this).data('href')
  // $('#proposal-modal').modal('show')
  return Turbo.visit($(this).data('href'));
});

// merge contract: template change
$(document).on('change', '#document_esignature_attributes_booking_document_template_id', function(event) {
  var target;
  target = `/documents/${window.documentApp.documentId}/edit/merge_contract`;
  return window.documentApp.saveDocument(target);
});

// contract: download enabled
$(document).on('change', '#contract-download-enabled', function(event) {
  var $contractEnabled, $this, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $contractEnabled = $('input#document_contract_download_enabled');
  return $contractEnabled.val(checked ? '1' : '0');
});

// golf: remove golf info
$(document).on('click', '.remove-golf-info', function(event) {
  var $this;
  event.preventDefault();
  $this = $(this);
  $this.parent().find('input.destroy').val('1');
  return $this.closest('tr').hide();
});

// golf: calculate total cost
$(document).on('change', '.cost-per-player, .players', function(event) {
  var $this, $tr, costPerPlayer, players, totalCost;
  $this = $(this);
  $tr = $this.closest('tr');
  players = $tr.find('.players').val();
  costPerPlayer = $tr.find('.cost-per-player').val();
  if ($.isNumeric(players) && $.isNumeric(costPerPlayer)) {
    totalCost = (players * costPerPlayer).toFixed(2);
    return $tr.find('.total-cost').val(totalCost);
  }
});

// catering worksheet: menu item selected
$(document).on('change', '#document_catering_item_menu_item_id', function() {
  var menuItemId;
  menuItemId = $(this).val();
  if (menuItemId !== "") {
    return $.ajax({
      url: `/menu_items/${menuItemId}.json`,
      success: function(data) {
        // $('#document_catering_item_expense_category').val(data.expense_category).trigger('change')
        $('#document_catering_item_menu_category_id').val(data.menu_category_id).trigger('change');
        $('#document_catering_item_name').val(data.name);
        $('#document_catering_item_notes').val(data.body);
        $('#document_catering_item_unit').val(data.unit);
        $('#document_catering_item_item_cost').val(data.item_cost);
        $('#document_catering_item_body').val(data.body);
        $('#document_catering_item_food_tax').val(data.food_tax);
        $('#document_catering_item_beverage_tax').val(data.beverage_tax);
        $('#document_catering_item_sales_tax').val(data.sales_tax);
        $('#document_catering_item_federal_sales_tax').val(data.federal_sales_tax);
        $('#document_catering_item_service_charge').val(data.service_charge);
        $('#document_catering_item_gratuities').val(data.gratuities);
        return $('#document_catering_item_room_rental_tax').val(data.room_rental_tax);
      }
    });
  }
});

// catering worksheet: menu item unselected
$(document).on('select2:unselect', '#document_catering_item_menu_item_id', function() {
  return window.documentApp.cateringWorksheetStep.clearFields();
});

// catering worksheet: delete catering item
$(document).on('click', '.delete-catering-item', function(event) {
  event.preventDefault();
  return $.post(`/documents/${window.documentApp.documentId}/document_catering_items/${$(this).data('id')}`, '_method=delete&format=js');
});

// catering worksheet: handle edit modal
$(document).on('click', '.edit-catering-item', function(event) {
  var $this;
  event.preventDefault();
  $this = $(this);
  $('#edit-catering-item-modal .modal-body').load($this.attr('href'));
  return $('#edit-catering-item-modal').modal('show');
});

// media: toggle media type
$(document).on('change', '#use-site-tour', function(event, state) {
  var $mediaType, $this, checked;
  $this = $(this);
  checked = $this.prop('checked');
  $mediaType = $('input#document_media_type');
  $mediaType.val(checked ? 'site_tour' : 'photos');
  return window.documentApp.mediaStep.manageSiteTourVisibility();
});

// media: add photo
$(document).on('click', '.add-photo', function(event) {
  var photo;
  event.preventDefault();
  photo = $(this).data();
  return window.documentApp.mediaStep.addPhoto(photo);
});

// media: add all photos
$(document).on('click', '.add-all-photos', function(event) {
  var cb, i, len, photo, photoElement, ref;
  event.preventDefault();
  ref = $('ul.media-list[data-photo-set-id=' + $(this).data('photo-set-id') + '] li a.add-photo');
  for (i = 0, len = ref.length; i < len; i++) {
    photoElement = ref[i];
    photo = $(photoElement).data();
    window.documentApp.mediaStep.addPhoto(photo);
  }
  $(this).tooltip({
    title: 'Added!'
  });
  $(this).tooltip('show');
  cb = function() {
    return $('.add-all-photos').tooltip('destroy');
  };
  return setTimeout(cb, 1500);
});

// media: delete photo
$(document).on('click', '.delete-photo', function(event) {
  var $photoList, data, i, idToDelete, len, photo;
  event.preventDefault();
  idToDelete = $(this).data('id');
  $photoList = $('ul.media-list.current li');
  for (i = 0, len = $photoList.length; i < len; i++) {
    photo = $photoList[i];
    data = $(photo).data();
    if (data.id === idToDelete) {
      $(photo).remove();
      break;
    }
  }
  window.documentApp.mediaStep.inputChanged();
  return window.documentApp.handleSavedState(false);
});

// media: turbo frame loaded
$(document).on('turbo:frame-load', function() {
  if ($('body').hasClass('documents') && $('body').hasClass('edit')) {
    return window.documentApp.mediaStep.initializeTooltips();
  }
});

// links: add custom link
$(document).on('click', 'a#add-custom-link', function(event) {
  var regexp, time;
  event.preventDefault();
  time = new Date().getTime();
  regexp = new RegExp($(this).data('id'), 'g');
  return $('div#custom-links table tbody').append($(this).data('fields').replace(regexp, time));
});

// links: remove custom link
$(document).on('click', 'a.remove-custom-link', function(event) {
  var $this;
  event.preventDefault();
  $this = $(this);
  $this.parent().find('input.destroy').val('1');
  return $this.closest('tr').hide();
});

// links: select all links from set
$(document).on('click', 'a.select-all-links', function(event) {
  var i, j, len, len1, linkElement, linkSetId, ref, ref1, results, results1;
  event.preventDefault();
  linkSetId = $(this).data('link-set-id');
  if (linkSetId) {
    ref = $('input[data-link-set-id=' + linkSetId + ']');
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      linkElement = ref[i];
      results.push($(linkElement).prop('checked', true));
    }
    return results;
  } else {
    ref1 = $('input.link-select');
    results1 = [];
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      linkElement = ref1[j];
      results1.push($(linkElement).prop('checked', true));
    }
    return results1;
  }
});

// links: changed the menu template
$(document).on('change', 'select#document_document_menu_attributes_menu_template_id', function(event) {
  var target;
  target = `/documents/${window.documentApp.documentId}/edit/links`;
  return window.documentApp.saveDocument(target);
});

// send: surpress default behavior of copy to clipboard button
$(document).on('click', '.copy-to-clipboard', function(event) {
  return event.preventDefault();
});

// send: highlight text
$(document).on('.the-link', 'click touchend', function(event) {
  var el, range, sel;
  if (event.target.firstChild.nodeType !== 3) {
    return;
  }
  el = event.target;
  sel = window.getSelection();
  range = document.createRange();
  range.setStart(el.firstChild, 0);
  range.setEnd(el.firstChild, el.innerHTML.length);
  sel.removeAllRanges();
  return sel.addRange(range);
});

// send: show private URL
$(document).on('change', '#show-private-url', function(event, state) {
  var $this, checked;
  $this = $(this);
  checked = $this.prop('checked');
  if (checked) {
    return $('.private-url').slideDown('fast');
  } else {
    return $('.private-url').slideUp('fast');
  }
});

// send: send options modal
$(document).on('click', 'a.open-send-options-modal', function(event) {
  event.preventDefault();
  return $('#send-options-modal').modal();
});

// send: send now button
$(document).on('click', 'a.send-now', function(event) {
  var $this;
  event.preventDefault();
  $this = $(this);
  $('form.simple_form').one('ajax:complete', function() {
    return window.top.location.href = $this.attr('href');
  });
  window.documentApp.blockUI();
  return window.documentApp.saveDocument(window.location.pathname);
});

//meeting_space: fills fields with default values from FunctionType
$(document).on('typeahead:selected', 'body.documents .meeting-function', function(event) {
  var name, rowId;
  event.preventDefault();
  name = $(this).val();
  rowId = this.id.replace('_function', '');
  return $.get("/function_types/get_times", {
    name: name
  }, function(data) {
    if ($(`#${rowId}_start`).val() === '') {
      $(`#${rowId}_start`).val(data.start);
    }
    if ($(`#${rowId}_end`).val() === '') {
      $(`#${rowId}_end`).val(data.end);
    }
    if ($(`#${rowId}_room`).val() === '') {
      $(`#${rowId}_room`).val(data.meeting_room_name);
    }
    if ($(`#${rowId}_number_setup`).val() === '') {
      $(`#${rowId}_number_setup`).val(data.number_setup);
    }
    if ($(`#${rowId}_rental`).val() === '') {
      $(`#${rowId}_rental`).val(data.forecast_rental_revenue);
    }
    if ($(`#${rowId}_fbmin`).val() === '') {
      $(`#${rowId}_fbmin`).val(data.food_and_bev_minimum);
    }
    if ($(`#${rowId}_notes`).val() === '') {
      return $(`#${rowId}_notes`).val(data.notes);
    }
  });
});

document.addEventListener('turbo:load', function() {
  var $body;
  $body = $('body');
  if ($body.hasClass('documents') && ($body.hasClass('new') || $body.hasClass('create'))) {
    window.documentApp.generalStep.handleDateSelection();
    window.documentApp.generalStep.handleCustomerTypeahead();
    window.documentApp.initializeTinyMCE();
  }
  if ($body.hasClass('documents') && $body.hasClass('edit')) {
    return window.documentApp.handleDocumentEdit();
  }
});
