import { Controller } from '@hotwired/stimulus'
import JSZip from "jszip";

export default class extends Controller {
  static targets = ["fieldsFrame", "field", "fileField", "dataFileInput", "submitButton"]

  toggleMode(e) {
    this.dataFileInputTarget.value = ""
    const unionFields = [...new Set([...this.fieldTargets, ...this.fileFieldTargets])]
    const params = unionFields.map(input => {
      const key = encodeURIComponent(input.name);
      if (input.type === "checkbox") {
        return `${key}=${input.checked ? "1" : "0"}`;
      }
      const value = encodeURIComponent(input.value);
      return `${key}=${value}`;
    }).join("&");
    this.fieldsFrameTarget.src = this.data.get("fields-base") + `?${params}`
  }

  fileSelected(e) {
    const file = e.target.files[0]; // Access the first file (assuming single file input)

    if (file) {
      const reader = new FileReader();
      const files = this.fileFieldTargets.map(input => input.value)
      // Read the file as an ArrayBuffer
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;

        // Load and inspect zip contents using JSZip
        const zip = new JSZip();
        zip.loadAsync(arrayBuffer).then((contents) => {
          // Iterate through files in the zip
          const archiveFiles = Object.keys(contents.files).map((path) => {
            const file = contents.files[path];
            return path.split('/').pop(); // Get only the file name
          })
          let missingFiles = []
          files.forEach(item => {
            if (!archiveFiles.includes(item)) {
              missingFiles.push(item)
            }
          })
          if (missingFiles.length > 0) {
            alert(`${missingFiles.join(", ")} missing from the zip file`)
            this.submitButtonTarget.disabled = true
            e.target.value = ""
          } else {
            this.submitButtonTarget.disabled = false
          }
        }).catch((error) => {
          alert(`Error reading zip file: ${error}`);
        });
      };

      // Trigger file read
      reader.readAsArrayBuffer(file);
    } else {
      console.log('No file selected');
    }
  }
}