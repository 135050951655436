import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'

export default class extends Controller {
  static targets = [
                    'filterForm',
                    'searchForm',
                    'searchInput',
                    'clearSearchButton',
                    'unreadField',
                    'sortByField',
                    'sortOrderField',
                    'selectViewAll',
                    'selectViewUnread',
                    'selectViewScheduledReports',
                    'selectViewBookmarkedReports',
                    'startDate',
                    'endDate',
                    'dateFilterContainer',
                    'dateFilterLink'
                  ]

  connect() {
    this.searchInputTarget.addEventListener('keyup', throttle(this.listenForSearch, 3000).bind(this))
    this.searchInputTarget.addEventListener('keydown', event => {
      if (event.keyCode == 13) {
        event.preventDefault()
        return false
      } else if (event.keyCode == 27) {
        this.clearSearch()
      }
    })
    window.handleRowWithSidebarHeight()
  }

  unblockUI() {
    window.unblockUI()
  }

  submitDateRange(event) {
    event.preventDefault()

    // Get the values from the datepicker inputs
    const datepickerStartDate = document.querySelector('#refresh_date_start_date').value
    const datepickerEndDate = document.querySelector('#refresh_date_end_date').value
      
    if (!datepickerStartDate || !datepickerEndDate) {
      alert('Please select both start and end dates')
      return
    }

    // Update the hidden inputs in the search form
    this.startDateTarget.value = datepickerStartDate
    this.endDateTarget.value = datepickerEndDate

    window.pleaseWait()
    this.fireSearchForm()     
  }

  listenForSearch(event) {
    this.query = event.target.value
    if (this.query.length == 0) {
      this.clearSearchButtonTarget.classList.add('hidden')
      this.fireSearchForm()
    } else if (this.query.length >= 3) {
      this.clearSearchButtonTarget.classList.remove('hidden')
      this.fireSearchForm()
    } else {
      this.clearSearchButtonTarget.classList.remove('hidden')
    }
  }

  clearSearch() {
    this.searchInputTarget.value = ''
    this.clearSearchButtonTarget.classList.add('hidden')
    this.fireSearchForm()
  }

  fireSearchForm() {
    window.pleaseWait()
    this.searchFormTarget.requestSubmit()
  }

  update() {
    window.pleaseWait()
    Rails.fire(this.filterFormTarget, 'submit')
  }

  setFilterAll(event) {
    event.preventDefault() 
    this.unreadFieldTarget.value = '0'
    this.selectViewAllTarget.className = ""    
    this.selectViewAllTarget.classList.add('active')    
    this.selectViewUnreadTarget.className = ""    
    this.fireSearchForm()
  }

  setFilterUnread(event) {
    event.preventDefault()
    if (this.unreadFieldTarget.value === '1') {
      this.unreadFieldTarget.value = '0'
      this.selectViewAllTarget.classList.add('active')
      this.selectViewUnreadTarget.classList.remove('active')
    } else {
      this.unreadFieldTarget.value = '1'
      this.selectViewAllTarget.classList.remove('active')
      this.selectViewUnreadTarget.classList.add('active')
    }  
    this.fireSearchForm()
  }
  
  setSort(event) {
    event.preventDefault()
    this.sortByFieldTarget.value = event.target.dataset.value
    this.sortOrderFieldTarget.value = this.sortOrderFieldTarget.value === 'desc' ? 'asc' : 'desc'
    this.fireSearchForm()
  }

  cancelDateFilter(event) {
    event.preventDefault()    
    this.startDateTarget.value = ''
    this.endDateTarget.value = ''  
    this.dateFilterContainerTarget.style.display = 'none'    
  }  

  showDateFilter(event) {
    event.preventDefault()
    this.dateFilterContainerTarget.style.display = 'inline'
  }

}