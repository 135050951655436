import { Controller } from '@hotwired/stimulus'
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'datepickerButton',
    'datepickerText',
    'startDateInput',
    'endDateInput',
  ]

  connect() {
    if (this.hasDatepickerButtonTarget) {
      $(this.datepickerButtonTarget).daterangepicker(this.dateRangePickerOptions, this.dateRangePickerCallback.bind(this))
    }
  }

  dateRangePickerCallback(start, end) {
    if (this.hasDatepickerTextTarget) {
      this.datepickerTextTarget.innerHTML = start.format('M/D/YY') + ' - ' + end.format('M/D/YY')
    }

    if (this.hasStartDateInputTarget) {
      this.startDateInputTarget.value = start.format('YYYY-MM-DD')
    }

    if (this.hasEndDateInputTarget) {
      this.endDateInputTarget.value = end.format('YYYY-MM-DD')
    }

    this.dispatch('filtered')
  }

  get dateRangePickerOptions() {
    let startDate;
    if (this.hasStartDateInputTarget) {
      startDate = moment(this.startDateInputTarget.value).format('M/D/YY')
    } else {
      startDate = moment().subtract(6, 'days').format('M/D/YY')
    }
    let endDate;
    if (this.hasEndDateInputTarget) {
      endDate = moment(this.endDateInputTarget.value).format('M/D/YY')
    } else {
      endDate = moment().subtract(6, 'days').format('M/D/YY')
    }
    return {
      clearClass: 'btn btn-default btn-sm',
      showDropdowns: true,
      startDate: startDate,
      endDate: endDate,
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'This Year-to-Date': [moment().startOf('year'), moment()],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')]
      },
      locale: {
        'format': "M/D/YY",
        'separator': " - ",
      }
    }
  }
}
