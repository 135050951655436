import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['input']
  static values = {
    options: Object
  }

  connect() {
    let selectInput = $(this.inputTarget)
    selectInput.select2(this.optionsValue)
    selectInput.on('change.select2', this.dispatchChange.bind(this))
  }

  dispatchChange(_) {
    let changeEvent = new CustomEvent('changed')
    this.inputTarget.dispatchEvent(changeEvent)

    if (this.selected.length > 0) {
      let selectedEvent = new CustomEvent('selected')
      this.selected.get(0).dispatchEvent(selectedEvent)
    }
  }

  clear() {
    $(this.inputTarget).val(null).trigger('change')
  }

  get selected() {
    return $(this.inputTarget).find(':selected')
  }
}
