import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  connect() {
    const options = {enableTime: true}
    if (this.element.dataset.noPast) {
      options["minDate"] = "today"
    }
    flatpickr(this.element, options)
  }
}