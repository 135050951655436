import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["moveProgram", "moveActivities"]

  change_date() {
    const bookingForm = document.querySelector(`#edit_booking_${window.bookingApp.bookingId}`)
    if (this.moveProgramTarget.checked) {
      const moveProgramInput = document.createElement("input");
      moveProgramInput.type = 'hidden'
      moveProgramInput.name = 'booking[move_program]'
      moveProgramInput.value = '1'
      bookingForm.appendChild(moveProgramInput)
    }
    if (this.moveActivitiesTarget.checked) {
      const moveActivitiesInput = document.createElement("input");
      moveActivitiesInput.type = 'hidden'
      moveActivitiesInput.name = 'booking[move_activities]'
      moveActivitiesInput.value = '1'
      bookingForm.appendChild(moveActivitiesInput)
    }
    this.save_booking()
  }

  save_booking() {
    window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`);
  }

}