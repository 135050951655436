import moment from 'moment'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

window.eventCalendarApp = {
  calendar: null,
  datePicker: null,
  property: null,
  switchPropertyForm: null,
  newEventButtonRendered: false,

  updateDateHash(date, viewName) {
    return window.location.hash = `#${date}.${viewName}`
  },

  handleDatePicker() {
    const $dp = $('<input type="text" class="hide fd-datepicker">')
    $('.fc-datePickerButton-button').append($dp)
    const dateOptions = {
      buttonText: "<i class='fa fa-calendar'></i>",
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd',
      showOn: 'both'
    }
    window.eventCalendarApp.datePicker = $dp.datepicker($.extend({}, dateOptions, {
      onSelect(selectedDate) {
        return window.eventCalendarApp.calendar.gotoDate(selectedDate)
      }
    }))
  },

  eventIndex() {
    let defaultDate, defaultView
    window.eventCalendarApp.newEventButtonRendered = false

    if (window.location.hash) {
      const dateAndView = window.location.hash.replace('#','').split('.')
      const date = dateAndView[0]
      const view = dateAndView[1]
      defaultDate = moment(date).format('YYYY-MM-DD')
      defaultView = view
    } else {
      defaultDate = new Date()
      defaultView = 'dayGridMonth'
    }

    const calendarEl = document.querySelector('.event-calendar')

    window.eventCalendarApp.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      initialDate: defaultDate,
      timeZone: window.timeZone,
      height: $(window).height() - $('.navbar-top').height() - 70,
      editable: false,
      selectable: false,
      eventTimeFormat: calendarTimeFormatEvent,
      headerToolbar: {
        start: 'prev,next datePickerButton today newEventButton',
        center: 'title',
        end: 'timeGridDay,timeGridWeek,dayGridMonth printButton'
      },
      buttonText: {
        today:    'Today',
        month:    'Month',
        week:     'Week',
        day:      'Day'
      },
      customButtons: {
        newEventButton: {
          text: 'New Event',
          click: () => {
            const view = window.eventCalendarApp.calendar.view
            window.top.location = `/events/new?return_to=${view.currentStart.toISOString()}.${view.type}`
          }
        },
        printButton: {
          text: 'Print (PDF)',
          click: () => {
            const view = window.eventCalendarApp.calendar.view
            const title = view.title
            const start = view.activeStart
            const end = view.activeEnd
            const property = window.eventCalendarApp.property ? window.eventCalendarApp.property : ''
            return window.open(`/events.pdf?property=${property}&calendar=${view.type}&start=${start}&end=${end}&title=${title}`, "_blank")
          }
        },
        datePickerButton: {
          text: '',
          click: () => {}
        }
      },
      events: {
        url: `/events.json?property=${window.eventCalendarApp.property}`
      },
      datesSet: (info) => {
        const viewName = info.view.type
        const startDate = info.view.currentStart
        return window.eventCalendarApp.updateDateHash(startDate.toISOString(), viewName)
      },
      dateClick: (info) => {
        window.top.location = `/events/new?date=${info.dateStr}&return_to=${info.view.currentStart.toISOString()}.${info.view.type}`
      },
      eventClick: (info) => {
        const event = info.event
        if (event.id) {
          if (event.extendedProps.booking) {
            window.top.location = `/bookings/${event.id}/edit/general`
          } else if (event.extendedProps.report) {
            if (event.extendedProps.report_event) { return }

            window.top.location = `/reporting/reports/${event.id}`
          } else {
            window.top.location = `/events/${event.id}/edit?return_to=${info.view.currentStart.toISOString()}.${info.view.type}`
          }
        }
      },
      // eventDataTransform: (event) => {
      //   if (event.allDay) {
      //     event.end = moment(event.end).add(1, 'second')
      //   }
      //   return event
      // },
      viewDidMount: () => {
        if (window.eventCalendarApp.switchPropertyForm) {
          const leftToolbar = document.getElementsByClassName('fc-toolbar-chunk')[0]
          const navButtons = leftToolbar.querySelectorAll('.fc-button-group')[0]
          navButtons.insertAdjacentHTML('beforebegin', window.eventCalendarApp.switchPropertyForm)
        }
      }
    })

    window.eventCalendarApp.calendar.render()
    this.handleDatePicker()
  },

  eventForm() {
    const endDatePicker = flatpickr('#event_end_date', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      minDate: document.querySelector('#event_start_date').value
    })

    const startDatePicker = flatpickr('#event_start_date', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange(selectedDates, selectedDate) {
        endDatePicker.setDate(selectedDate)
        return endDatePicker.set('minDate', selectedDate)
      }
    })

    // timepickers
    const timepickerOptions = { timeFormat: timepickerFormat }
    $('.start-time').timepicker(timepickerOptions)
    return $('.end-time').timepicker(timepickerOptions)
  }
}

$(document).on('click', 'body.events #event_all_day', function(event) {
  const checked = $(this).prop('checked')
  if (checked) {
    $('.start-time').hide()
    return $('.end-time').hide()
  } else {
    $('.start-time').show()
    return $('.end-time').show()
  }
})

document.addEventListener('turbo:load', function() {
  const $body = $('body')
  if ($body.hasClass('events')) {
    if ($body.hasClass('index')) {
      window.eventCalendarApp.eventIndex()
      window.addEventListener('resize', () => {
        window.eventCalendarApp.calendar.setOption('height', $(window).height() - $('.navbar-top').height() - 70)
      })
    } else {
      return window.eventCalendarApp.eventForm()
    }
  }
})
