import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []
  static values = {
    loadingHtml: String
  }

  connect() {
    if (!this.hasLoadingHtmlValue) {
      this.loadingHtmlValue = this.element.innerHTML
    }
  }

  display(_) {
    this.element.innerHTML = this.loadingHtmlValue
  }
}
