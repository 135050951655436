import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  uncheck(_) {
    if (this.inputTarget.checked) {
      this.inputTarget.checked = false
      this.dispatch('unchecked', { target: this.element })
    }
  }
}
