import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectInput']

  selectAll(e) {
    e.preventDefault()
    this.selectInputTarget.querySelectorAll('option').forEach((el) => {
      el.selected = (el.textContent.length > 0)
    })
  }
}