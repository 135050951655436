import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    name: String,
    hide: { type: Boolean, default: false }
  }

  connect() {
    if (this.hideValue) {
      this.hide()
    } else {
      this.show()
    }
  }

  toggleTargets({ params: { targets, show } }) {
    targets.split(' ').forEach((target) => {
      this.dispatch('toggled', { detail: { name: target, show: show } })
    })
  }

  showTargets({ params: { targets } }) {
    targets.split(' ').forEach((target) => {
      this.dispatch('toggled', { detail: { name: target, show: true } })
    })
  }

  hideTargets({ params: { targets } }) {
    targets.split(' ').forEach((target) => {
      this.dispatch('toggled', { detail: { name: target, show: false } })
    })
  }

  toggle({ detail: { name, show } }) {
    if (name == this.nameValue) {
      if (show) {
        this.show()
      } else {
        this.hide()
      }
    }
  }

  show(_) {
    this.element.classList.remove('d-none')
    this.dispatch('shown', { target: this.element })
  }

  hide(_) {
    this.element.classList.add('d-none')
    this.dispatch('hidden', { target: this.element })
  }

  // this probably belongs somewhere else
  expand(_) {
    this.element.classList.add('w-100')
  }

  contract(_) {
    this.element.classList.remove('w-100')
  }
}
