import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', function(event) {
      if (!this.element.checkValidity()) {
        event.preventDefault(); // Prevent form submission if invalid
        alert("Please fill in all required fields")
      }
    }.bind(this))
  }
}