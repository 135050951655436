/* eslint no-console:0 */

// jQuery - needed to hoist the $ function
import './js/jquery'

import './recurring_select'

import 'moment'

require('@rails/ujs').start()

import '@hotwired/turbo-rails'
import "@rails/request.js"
import 'jquery-ui-dist/jquery-ui'
import '@fancyapps/fancybox'
import 'cycle2'
import 'lodash'
import './src/cocoon'
import 'bootstrap'
import 'bootstrap-toggle'
import select2 from 'select2'
window.select2 = select2()
import 'mediaelement'
import 'corejs-typeahead'
import 'bloodhound-js'
import 'daterangepicker'
import 'hogan.js'
import 'chartkick/chart.js'
import 'chart.js'
import 'fabric'
import 'plupload'
import 'clipboard'
import 'fixed-data-table-2'
import 'flatpickr'
import 'dropzone'
import 'core-js'
import '@fullcalendar/resource'

// JS from source (one-off scripts in src directory) 
import './src/bootstrap-popover-reposition'
import './src/image-picker'
import './src/bootstrap-multiselect'
import './src/accounting.min'
import './src/autosize'
import './src/jquery.monthpicker'
import './src/jquery.timepicker'
import './src/jquery.blockui'

// React
import './js/react'

// Stimulus – manage in app/javascript/js/stimulus/index.js
import './js/stimulus'

// Our app's JavaScript/Coffeescript – manage in app/javascript/js/index.js
import './js'
